import { getGenerativeModel, Schema } from "firebase/vertexai";
import { vertexAI } from "./fbConfig";

// Generic Gemini Model
export const gemini_model = getGenerativeModel(vertexAI, { model: "gemini-1.5-pro" });

// Modification Model
const modify_model_prompt = `You are a text editing assistant that modifies texts according to user requests.

The language of the output text MUST ALWAYS be identical to the language of the "Text to be modified:". The rest of the document doesn't matter, only the text to be modified is relevant for the language.
The only exception is when a translation is explicitly requested.

FORMATTING RULES:
- Format the text as HTML with <p>, <ul>, <li>, <em> tags
- Only keep bold and italic formatting where it was used in the original
- Return only the modified text, without additional explanations
- The text should sound natural and fit the context`;

export const modify_model = getGenerativeModel(vertexAI, { 
  model: "gemini-1.5-pro", 
  systemInstruction: modify_model_prompt
});

// Translation Model
const translation_model_prompt = `You're a translation assistant. You will get a Text in an unspecified language, and your job is to translate it into English.

The output should be fully in the target language, without any additional text or explanations.
You should keep the original text structure and formatting. The text will be formatted as HTML with <p>, <ul>, <li>, <em> tags.

You will first receive the text to translate, then the instructions for the translation.`;

export const translation_model = getGenerativeModel(vertexAI, { 
  model: "gemini-1.5-pro", 
  systemInstruction: translation_model_prompt
});

// Suggestion Model Schema
const suggestionSchema = Schema.object({
  properties: {
    suggestion1: Schema.string(),
    suggestion2: Schema.string(),
    suggestion3: Schema.string()
  },
  required: ["suggestion1", "suggestion2", "suggestion3"]
});

export const suggestion_model = getGenerativeModel(
  vertexAI,
  {
    model: "gemini-1.5-pro",
    generationConfig: {
      responseMimeType: "application/json",
      responseSchema: suggestionSchema
    }
  }
);

// Input Suggestions Model
const inputSuggestionsSchema = Schema.object({
  properties: {
    suggestion1: Schema.object({
      properties: {
        title: Schema.string(),
        description: Schema.string(), 
        type: Schema.string()
      },
      required: ["title", "description", "type"]
    }),
    suggestion2: Schema.object({
      properties: {
        title: Schema.string(),
        description: Schema.string(),
        type: Schema.string()
      },
      required: ["title", "description", "type"]
    })
  },
  required: ["suggestion1", "suggestion2"]
});

const input_suggestions_prompt = `Du bist ein KI-Assistent, der dabei hilft, zusätzliche Eingabefelder für Dokumentvorlagen zu generieren.
Deine Aufgabe ist es, den Vorlagenkontext und die vorhandenen Eingaben zu analysieren, um relevante, nicht redundante zusätzliche Felder vorzuschlagen.

Jeder Vorschlag sollte:
1. Kontextuell relevant für den Zweck der Vorlage sein
2. Wertvolle zusätzliche Informationen erfassen
3. Keine vorhandenen Felder duplizieren
4. Klar und spezifisch in Bezug auf die benötigten Informationen sein

Für jedes Feld spezifiziere:
- title: Ein prägnanter, klarer Titel für das Feld
- description: Eine hilfreiche Erklärung, welche Information eingegeben werden soll
- type: Entweder "input" (für einzeilige Texte) oder "textarea" (für längere, mehrzeilige Inhalte)

Verwende "textarea" wenn:
- Die erwartete Eingabe mehr als einen Satz umfasst
- Das Feld eine detaillierte Erklärung oder Kontext erfordert
- Die Information komplex ist oder Ausführung benötigt

Verwende "input" wenn:
- Eine einzelne Textzeile ausreicht
- Die Information kurz und spezifisch ist
- Das Feld eine einfache, direkte Information erfasst

Gib genau zwei Feldvorschläge in einem strukturierten JSON-Format zurück.`;

export const input_suggestions_model = getGenerativeModel(
  vertexAI,
  {
    model: "gemini-1.5-pro",
    systemInstruction: input_suggestions_prompt,
    generationConfig: {
      responseMimeType: "application/json",
      responseSchema: inputSuggestionsSchema
    }
  }
); 