import { doc, getDoc, onSnapshot, getDocs } from "firebase/firestore";
import { firestore } from '../config/fbConfig';
import { collection } from "firebase/firestore";

// Export the interface so it can be used in other files
export interface OrganizationMember {
  role: string;
  joined_at: Date;
  uid: string;
  displayName: string;
  email: string;
  status: string;
  fullname?: string;
}

export interface Organization {
  id: string;
  name: string;
  created_at: Date;
  owner_id: string;
  members: {
    [userId: string]: OrganizationMember;
  };
  templates?: Template[];
}

// Add interface for member data
interface UserData {
  fullName?: string;
  displayName?: string;
  email?: string;
}

// Add Template interface
export interface Template {
  id: string;
  title: string;
  type: string;
  favorite?: boolean;
  iconName?: string;
  iconColor?: string;
  description?: string;
  organizationId?: string;
  placeholders?: Array<{
    label: string;
    type: string;
  }>;
  organizationName?: string;
}

// Add Persona interface
export interface Persona {
  id: string;
  name: string;
  personatext: string;
  type: string;
  created_at: Date;
  originalOwnerId?: string;
  sharedAt?: Date;
  sharedByUserId?: string;
  organizationId?: string;
  standard?: boolean;
  isPersonal?: boolean;
}

// Add new types for the subscription callback
export type OrganizationSubscriptionCallback = (organizations: Organization[]) => void;
export type TemplateSubscriptionCallback = (templates: Template[]) => void;
export type PersonaSubscriptionCallback = (personas: Persona[]) => void;

// Add a new function to handle real-time organization updates
export const subscribeToUserOrganizations = (
  userId: string,
  callback: OrganizationSubscriptionCallback
): (() => void) => {
  const userDocRef = doc(firestore, "users", userId);
  const templateUnsubscribes: (() => void)[] = [];
  
  const unsubscribe = onSnapshot(userDocRef, async (userSnapshot) => {
    try {
      const userData = userSnapshot.data();
      if (!userData?.organizations) {
        callback([]);
        return;
      }

      const organizationIds = Object.keys(userData.organizations);
      const orgRefs = organizationIds.map(orgId => 
        doc(firestore, "organizations", orgId)
      );

      // Clear previous template subscriptions
      templateUnsubscribes.forEach(unsub => unsub());
      templateUnsubscribes.length = 0;

      // Subscribe to all organizations
      const orgUnsubscribes = orgRefs.map((orgRef) => 
        onSnapshot(orgRef, async (orgSnapshot) => {
          if (!orgSnapshot.exists()) return;
          
          const organizations = await Promise.all(
            orgRefs.map(async (ref) => {
              const orgDoc = await getDoc(ref);
              if (!orgDoc.exists()) return null;

              const orgData = orgDoc.data();
              const templates: Template[] = [];

              // Set up template subscription for this organization
              const templateRef = collection(firestore, "organizations", ref.id, "templates");
              const templateUnsubscribe = onSnapshot(templateRef, (templatesSnap) => {
                templates.length = 0; // Clear existing templates
                
                templatesSnap.forEach((templateDoc) => {
                  const templateData = templateDoc.data();
                  if (templateData.template_data) {
                    templates.push({
                      ...templateData.template_data,
                      id: `${ref.id}_${templateDoc.id}`,
                      organizationId: ref.id,
                      organizationName: orgData.name
                    });
                  } else if (templateData.title && templateData.type) {
                    templates.push({
                      id: `${ref.id}_${templateDoc.id}`,
                      title: templateData.title,
                      type: templateData.type,
                      favorite: templateData.favorite,
                      organizationId: ref.id,
                      organizationName: orgData.name
                    });
                  }
                });

                // Trigger callback with updated organization data including templates
                const validOrganizations = organizations
                  .filter((org): org is Organization => org !== null)
                  .map(org => org.id === ref.id ? { ...org, templates } : org);
                
                callback(validOrganizations);
              });

              templateUnsubscribes.push(templateUnsubscribe);

              return {
                id: ref.id,
                ...orgData,
                templates
              } as Organization;
            })
          );

          const validOrganizations = organizations.filter((org): org is Organization => org !== null);
          callback(validOrganizations);
        })
      );

      return () => {
        unsubscribe();
        orgUnsubscribes.forEach(unsub => unsub());
        templateUnsubscribes.forEach(unsub => unsub());
      };
    } catch (error) {
      console.error("Error in organization subscription:", error);
      callback([]);
    }
  });

  return () => {
    unsubscribe();
    templateUnsubscribes.forEach(unsub => unsub());
  };
};

export const fetchUserOrganizations = async (userId: string): Promise<Organization[]> => {
  try {
    // First get the user document to check for organizations
    const userDoc = await getDoc(doc(firestore, "users", userId));
    const userData = userDoc.data();
    if (!userData?.organizations) return [];
    const organizationIds = Object.keys(userData.organizations);

    // Fetch each organization's full data
    const organizationsPromises = organizationIds.map(async (orgId) => {
      const orgDoc = await getDoc(doc(firestore, "organizations", orgId));
      if (!orgDoc.exists()) return null;

      const orgData = orgDoc.data();

      return {
        id: orgDoc.id,
        ...orgData
      } as Organization;
    });

    const organizations = await Promise.all(organizationsPromises);
    return organizations.filter((org): org is Organization => org !== null);
  } catch (error) {
    console.error("Error fetching organizations:", error);
    throw error;
  }
};

export const subscribeToOrganizationTemplates = (
  organizations: Organization[],
  callback: TemplateSubscriptionCallback
): (() => void) => {
  const unsubscribes: (() => void)[] = [];

  organizations.forEach((org) => {
    const orgTemplatesRef = collection(firestore, "organizations", org.id, "templates");
    
    const unsubscribe = onSnapshot(orgTemplatesRef, (orgTemplatesSnap) => {
      const orgTemplates: Template[] = [];
      
      orgTemplatesSnap.forEach((doc) => {
        const templateData = doc.data();
        if (templateData.template_data) {
          orgTemplates.push({
            ...templateData.template_data,
            id: `${org.id}_${doc.id}`,
            organizationId: org.id,
            organizationName: org.name
          });
        } else if (templateData.title && templateData.type) {
          orgTemplates.push({
            id: `${org.id}_${doc.id}`,
            title: templateData.title,
            type: templateData.type,
            favorite: templateData.favorite,
            organizationId: org.id,
            organizationName: org.name
          });
        }
      });

      callback(orgTemplates);
    });

    unsubscribes.push(unsubscribe);
  });

  return () => unsubscribes.forEach(unsub => unsub());
};

// Add subscription function for organization personas
export const subscribeToOrganizationPersonas = (
  organizations: Organization[],
  callback: PersonaSubscriptionCallback
): (() => void) => {
  const unsubscribes: (() => void)[] = [];

  organizations.forEach((org) => {
    const orgPersonasRef = collection(firestore, "organizations", org.id, "personas");
    
    const unsubscribe = onSnapshot(orgPersonasRef, (orgPersonasSnap) => {
      const orgPersonas: Persona[] = [];
      
      orgPersonasSnap.forEach((doc) => {
        const personaData = doc.data();
        orgPersonas.push({
          id: `${org.id}_${doc.id}`,
          name: personaData.name,
          personatext: personaData.personatext,
          type: personaData.type,
          created_at: personaData.created_at?.toDate(),
          originalOwnerId: personaData.originalOwnerId,
          sharedAt: personaData.sharedAt?.toDate(),
          sharedByUserId: personaData.sharedByUserId,
          organizationId: org.id,
          standard: false
        });
      });
      callback(orgPersonas);
    });

    unsubscribes.push(unsubscribe);
  });

  return () => unsubscribes.forEach(unsub => unsub());
}; 