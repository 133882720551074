import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "~/components/ui/card";
import { Button } from "~/components/ui/button";
import { Building2, Check } from "lucide-react";

const FEATURES = [
  "Unbegrenzte Teammitglieder",
  "Gemeinsame Vorlagen",
  "Gemeinsame Personas",
  "Zentrales Template Management",
  "Rollenbasierte Zugriffsrechte",
  "Dedizierter Support"
];

export default function OrganizationPaywall() {
  const handleTalkToSales = () => {
    window.open("https://robowriter.de/demo-buchen", "_blank");
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center gap-4">
          <Building2 className="w-8 h-8 text-primary" />
          <div>
            <CardTitle>Nutze Robowriter als Team</CardTitle>
            <CardDescription>
              Organisationen sind nur im Team-Tarif verfügbar
            </CardDescription>
          </div>
        </div>
      </CardHeader>
      <CardContent className="space-y-6">
        <ul className="space-y-3">
          {FEATURES.map((feature, index) => (
            <li key={index} className="flex items-center gap-2">
              <Check className="w-5 h-5 text-primary flex-shrink-0" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>

        <Button 
          onClick={handleTalkToSales}
          size="lg"
          className="w-full"
        >
          Mit dem Vertrieb sprechen
        </Button>
      </CardContent>
    </Card>
  );
} 