import { Button } from "~/components/ui/button"
import { Loader2, Zap } from "lucide-react"
import { cn } from "~/lib/utils"

interface GenerateButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isGenerating?: boolean;
  hasGenerated?: boolean;
  className?: string;
}

export const GenerateButton = ({
  isGenerating = false,
  hasGenerated = false,
  className,
  ...props
}: GenerateButtonProps) => {
  return (
    <Button
      variant="ghost"
      size="sm"
      className={cn(
        "text-xs font-normal text-primary hover:text-primary hover:bg-primary/10 px-1.5 py-1",
        isGenerating && "text-primary",
        className
      )}
      disabled={isGenerating}
      {...props}
    >
      {isGenerating ? (
        <>
          <Loader2 className="h-2 w-2 animate-spin text-primary" />
          Generiere...
        </>
      ) : (
        <>
          <Zap className="h-2 w-2 text-purple-700" />
          {hasGenerated ? "Eingabe neu generieren" : "Eingabe generieren"}
        </>
      )}
    </Button>
  )
} 