import React, { useEffect, useState, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import LayoutInSide from "../../layouts/LayoutInSide";
import SpinningLoader from "../../components/loader/spinning_loader";
import { getUserTier } from "../../utils/userUtils";
import { toast } from "react-toastify";
import {
    doc,
    getDoc,
    firestore,
    onSnapshot,
    addDoc,
    serverTimestamp,
    deleteDoc,
    updateDoc,
    auth,
} from "../../config/fbConfig";
import { gemini_model } from "../../config/models";
import { usePostHog } from 'posthog-js/react';

// Modular Firebase SDK imports
import { collection, getDocs } from 'firebase/firestore';
import { useShareableData } from "../../providers/ShareableDataProvider";
import { UserGuideStepEnum } from "../../consts";
import PersonaContent from "~/components/persona/creation";
import PersonaForm from "~/components/persona/final";
import WelcomeView from "~/components/persona/welcomeView";
import PersonaBanner from "~/components/ui/persona-banner";
import { PERSONA_ANALYSIS_PROMPT, PERSONA_TITLE_PROMPT } from '../../prompts/personaPrompts';
import { useUserData } from "../../providers/UserDataProvider";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../config/fbConfig";

interface Persona {
    id: string;
    name: string;
    personatext: string;
    isPersonal?: boolean;
    organizationId?: string;
}

const PersonaPage: React.FC = () => {
    const queryClient = useQueryClient();
    const [input, setInput] = useState<string>('');
    const [analyzeText, setAnalyzeText] = useState<string>('');
    const [analyzeError, setAnalyzeError] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [editId, setEditId] = useState<string>('');
    const [showPersonaForm, setShowPersonaForm] = useState<boolean>(false);
    const [showFinalForm, setShowFinalForm] = useState<boolean>(false);
    const [inputError, setInputError] = useState<string>('');
    const [isAnalyzing, setIsAnalyzing] = useState<boolean>(false);
    const [isDefault, setIsDefault] = useState<boolean>(false);
    const [isBannerVisible, setIsBannerVisible] = useState<boolean>(false);
    const { showOnBoarding, completeOnBoardingStep } = useShareableData();
    const [creations, setCreations] = useState<number | null>(0);
    const { organizations, personas } = useUserData();
    const [isSharing, setIsSharing] = useState<string | null>(null);

    const posthog = usePostHog();

    // Remove the personas query since we're getting it from UserDataProvider
    const isPersonasLoading = false;

    // Handle adding a new persona
    const addPersonaMutation = useMutation<void, Error, { name: string; personatext: string }>({
        mutationFn: async ({ name, personatext }) => {
            if (auth.currentUser) {
                const personasCollectionRef = collection(firestore, "users", auth.currentUser.uid, "personas");
                const doc = await addDoc(personasCollectionRef, {
                    "name": name,
                    "personatext": personatext,
                    "created_at": serverTimestamp()
                });
                setInput("")
                setName("")
                setEditId("")
                posthog.capture('tonfall_page:new_tonfall', {
                    tonfall_name: name,
                    tonfall: personatext,
                });
                if (showOnBoarding) {
                    completeOnBoardingStep(UserGuideStepEnum.TONE)
                }
                setShowFinalForm(false);
                setShowPersonaForm(false);

                if (isDefault) {
                    handleSetDefault(doc.id);
                    setIsDefault(false);
                }

            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['get-personas'] });
            resetForm();
        },
    });

    // Handle updating an existing persona
    const updatePersonaMutation = useMutation<void, Error, Persona>({
        mutationFn: async ({ id, name, personatext }) => {
            const personaDocRef = doc(firestore, "users", auth.currentUser!.uid, "personas", id);
            await updateDoc(personaDocRef, {
                name,
                personatext,
                created_at: serverTimestamp(),
            });
            if (isDefault) {
                handleSetDefault(id);
                setIsDefault(false);
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['get-personas'] });
            resetForm();
            setShowFinalForm(false);
            setShowPersonaForm(false);
        },
    });

    // Handle deleting a persona
    const deletePersonaMutation = useMutation<void, Error, string>({
        mutationFn: async (id) => {
            const personaDocRef = doc(firestore, "users", auth.currentUser!.uid, "personas", id);
            await deleteDoc(personaDocRef);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['get-personas'] });
            resetForm();
        },
    });

    // Handle duplicating a persona
    const handleDuplicatePersona = (persona: Persona) => {
        const duplicatedPersona = {
            name: `${persona.name}-copy-${Date.now()}`,
            personatext: persona.personatext,
        };
        addPersonaMutation.mutate(duplicatedPersona);
    };

    const handleNameFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setName(e.target.value);
    };
    const handleTextFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInput(e.target.value);
        if (inputError) {
            setInputError('');
        }
    };
    const handleAnalyzeFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAnalyzeText(e.target.value);
        if (inputError) {
            setInputError('');
        }
    };

    const handleAnalyze = async (event: React.FormEvent) => {
        event.preventDefault();
        if (analyzeText.trim() === "") {
            setInputError("Field can't be empty.");
            return;
        }
        setInputError("");
        setIsAnalyzing(true);

                try {
                    const result = await gemini_model.generateContentStream(`${PERSONA_ANALYSIS_PROMPT}\n${analyzeText}`);

                    let geminiResponse = "";
                    for await (const chunk of result.stream) {
                        geminiResponse += chunk.text();
                    }

                    const result2 = await gemini_model.generateContentStream(`${PERSONA_TITLE_PROMPT}\n\n${geminiResponse}`);

                    let geminiResponse2 = "";
                    for await (const chunk of result2.stream) {
                        geminiResponse2 += chunk.text();
                    }

                    const analyzedData = {
                        name: geminiResponse2.trim(),
                        description: geminiResponse.trim(),
                    };

                    setName(analyzedData.name)
                    setInput(analyzedData.description);
                    setShowFinalForm(true);
                } catch (error) {
                    console.error('Error analyzing tone:', error);
                    setAnalyzeError(`Es ist ein Fehler aufgeteten, bitte versuche es noch einmal.`)
                }
                setIsAnalyzing(false);
    };

    const handleToggleChange = () => {
        setIsDefault(!isDefault);
    };

    const setDefaultMutation = useMutation<void, Error, string>({
        mutationFn: async (id) => {
            if (!auth.currentUser) throw new Error("User not authenticated");
            
            // Update writedata collection instead of personas
            const writeDataDocRef = doc(firestore, "users", auth.currentUser.uid, "writedata", "appdata");
            await updateDoc(writeDataDocRef, { defaultPersonaId: id });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['get-personas'] });
        },
    });

    const handleSetDefault = (id: string) => {
        setDefaultMutation.mutate(id);
    };

    const submitNewPersona = (event: React.FormEvent) => {
        event.preventDefault();
        if (personas?.some(persona => persona.name === name)) {
            toast.error("Ein Tonfall mit dem Namen existiert bereits. Bitte wähle einen anderen Namen für deinen Tonfall.");
            return;
        }
        if (name && input) {
            addPersonaMutation.mutate({ name, personatext: input });
        }
    };

    const submitEditedPersona = (event: React.FormEvent) => {
        event.preventDefault();
        const oldPersona = personas?.find(persona => persona.id === editId);
        if (oldPersona?.name !== name  && personas?.some(persona => persona.name === name)) {
            toast.error("Ein Tonfall mit dem Namen existiert bereits. Bitte wähle einen anderen Namen für deinen Tonfall.");
            return;
        }
        updatePersonaMutation.mutate({ id: editId, name: name, personatext: input });
    };

    const deletePersonaById = (id: string) => async () => {
        deletePersonaMutation.mutate(id);
    };

    const editPersonaById = (id: string) => async () => {
        if (auth.currentUser) {
            const personaDocRef = doc(firestore, "users", auth.currentUser.uid, "personas", id);
            const personaDoc = await getDoc(personaDocRef);
            const personaDocData = personaDoc.data();
            setName(personaDocData?.name || "")
            setInput(personaDocData?.personatext || "")
            setEditId(personaDoc?.id || "")
            setShowFinalForm(true);
        }
    }

    const resetForm = () => {
        setInput("");
        setName("");
        setEditId("");
        setShowPersonaForm(false);
        setIsDefault(false);
        setAnalyzeText("")
        setAnalyzeError("")
    };

    const backToCreation = () => {
        setShowFinalForm(false);
        setIsDefault(false);
        if (editId) {
            setEditId("");
            setInput("");
            setName("");
        }
    }
    
    useEffect(() => {
        const loadBannerState = async () => {
            if (!auth.currentUser) throw new Error("User not authenticated");
            const { creations } = await getUserTier(auth.currentUser.uid);
            setCreations(creations)
            if(!creations) {
                setIsBannerVisible(true)
            }
        };

        loadBannerState();
    }, []);

    const handleShare = async (personaId: string, orgId: string) => {
        if (!auth.currentUser?.uid) return;
        
        setIsSharing(orgId);
        const sharePersona = httpsCallable(functions, 'share_persona_with_organization');
        
        try {
            await sharePersona({
                personaId,
                organizationId: orgId
            });
            
            toast.success(`Tonfall erfolgreich mit Organisation geteilt`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            
        } catch (error) {
            console.error("Error sharing persona:", error);
            toast.error("Fehler beim Teilen des Tonfalls. Bitte versuchen Sie es erneut.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setIsSharing(null);
        }
    };

    const handleUnshare = async (personaId: string, orgId: string) => {
        if (!auth.currentUser?.uid) return;
        
        const unsharePersona = httpsCallable(functions, 'unshare_persona_from_organization');
        try {
            await unsharePersona({
                personaId: personaId.split("_").pop() || personaId,
                organizationId: orgId
            });
            
            toast.success(`Tonfall erfolgreich von Organisation entfernt`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            console.error("Error unsharing persona:", error);
            toast.error("Fehler beim Entfernen des Tonfalls. Bitte versuchen Sie es erneut.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const isOrgAdmin = useCallback((orgId: string) => {
        if (!auth.currentUser?.uid) return false;
        
        return organizations?.some(org => 
            org.id === orgId && 
            org.members?.[auth.currentUser!.uid]?.role === 'admin'
        ) ?? false;
    }, [organizations]);

    if (isPersonasLoading) {
        return <SpinningLoader />;
    }

    if (!personas) {
        return <SpinningLoader />;
    }

    return (
        <LayoutInSide>
            <div className="flex h-full overflow-hidden">
                {/* Grey Line */}
                <div className="w-[20px] hidden sm:flex bg-light"></div>
                {/* Main Content */}
                <div className="flex-1">
                    {isBannerVisible && <PersonaBanner setIsBannerVisible={setIsBannerVisible} />}
                    {showFinalForm ? (
                        <PersonaForm
                            name={name}
                            input={input}
                            handleNameFieldChange={handleNameFieldChange}
                            handleTextFieldChange={handleTextFieldChange}
                            submitNewPersona={editId ? submitEditedPersona : submitNewPersona}
                            handleBackToContent={backToCreation}
                            handleAnalyze={handleAnalyze}
                            isEditing={!!editId}
                            isDefault={isDefault}
                            handleToggleChange={handleToggleChange}
                            isAnalyzing={isAnalyzing}
                            analyzeError={analyzeError}
                        />
                    ) : showPersonaForm ? (
                        <PersonaContent
                            analyzeText={analyzeText}
                            handleAnalyzeFieldChange={handleAnalyzeFieldChange}
                            setShowPersonaForm={setShowPersonaForm}
                            handleAnalyze={handleAnalyze}
                            handleBackToContent={resetForm}
                            inputError={inputError}
                            analyzeError={analyzeError}
                            isAnalyzing={isAnalyzing}
                        />
                    ) : (
                        <WelcomeView
                            personas={personas}
                            editPersonaById={editPersonaById}
                            deletePersonaById={deletePersonaById}
                            setShowPersonaForm={setShowPersonaForm}
                            handleDuplicatePersona={handleDuplicatePersona}
                            handleSetDefault={handleSetDefault}
                            creations={creations}
                            handleShare={handleShare}
                            handleUnshare={handleUnshare}
                            organizations={organizations}
                            isOrgAdmin={isOrgAdmin}
                        />
                    )}
                </div>
            </div>
        </LayoutInSide>
    );
};

export default PersonaPage;
