import React, { useState, useEffect } from "react";
import Container from "../../components/Container";
import NavbarAuth from "~/layouts/NavbarAuth";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
} from "firebase/auth";
import Footer from "../../layouts/FooterInside";
import { toast } from "react-toastify";
import { httpsCallable } from "firebase/functions";
import { auth, functions } from "../../config/fbConfig";
import { Link } from "react-router-dom";
import { ClosedEye, Eye } from "../../assets/Icons";
import GoogleSignInButton from "../../components/Google-sign-in";
import { checkUserState } from "../../utils/loginUtils";
import SpinningLoader from "../../components/loader/spinning_loader";
import { Helmet } from "react-helmet";
import { useDistinctIdFromUrl, useHandleUserIdentification, appendDistinctIdToUrl } from '../../utils/UserTrackingUtils';
import { usePostHog } from 'posthog-js/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Button } from "~/components/ui/button";
import { ArrowLeft } from "lucide-react";

const SignUpPage = () => {

  // State Variables
  const [loading, setLoading] = useState(false);
  const [len8Ok, setLen8Ok] = useState(false);
  const [largeLetterOk, setLargeLetterOk] = useState(false);
  const [numberOk, setNumberOk] = useState(false);
  const [specialCharOk, setSpecialCharOk] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState({ first_name: "", last_name: "", email: "", password: "" });

  const [showEmailForm, setShowEmailForm] = useState(false);

  // Providers
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();
  // Get distinct id from url, relevant for cross-site-tracking
  const distinctId = useDistinctIdFromUrl();
  const posthog = usePostHog();

  const params = new URLSearchParams(window.location.search);
  const req = params.get("req") ?? "";
  const email = params.get("email") ?? "";

  const [formData, setFormData] = useState({ first_name: "", last_name: "", email: email, password: "", newsletter: "off" });


  // Get distinct id from url and identify user
  useHandleUserIdentification(distinctId);

  // Functions & Event Handlers

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    let fieldValidationErrors = formErrors;

    switch (name) {
      case "password":
        const hasUpperCase = value.toLowerCase() !== value;
        const hasNumber = /\d/.test(value);
        const hasSpecialCharacter = /[ \W|_]/.test(value);
        setLen8Ok(value.length >= 8);
        setLargeLetterOk(hasUpperCase);
        setNumberOk(hasNumber);
        setSpecialCharOk(hasSpecialCharacter);
        if (value.length < 8) {
          fieldValidationErrors.password = "Das Passwort muss mindestens 8 Zeichen lang sein.";
        } else if (!hasUpperCase) {
          fieldValidationErrors.password = "Passwort muss mindestens einen Großbuchstaben enthalten.";
        } else if (!hasNumber) {
          fieldValidationErrors.password = "Passwort muss mindestens eine Zahl enthalten.";
        } else if (!hasSpecialCharacter) {
          fieldValidationErrors.password = "Passwort muss mindestens ein Sonderzeichen enthalten.";
        } else {
          fieldValidationErrors.password = "";
        }
        break;
      case "email":
        fieldValidationErrors.email = value.match(
          /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        )
          ? ""
          : "E-Mail ist ungültig";
        break;
      default:
        break;
    }
    setFormErrors({ ...fieldValidationErrors });
  };



  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitted(true);
    setFormValid(Object.values(formErrors).every((error) => error === ""));
  };

  const createUserDocumentFunction = httpsCallable(functions, "user_authentication_handler");

  const signUpUser = async (
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    newsletter: boolean
  ) => {
    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const user = userCredential.user;
      const uid = user?.uid;

      if (posthog) {
        try {
          const lastSignInTime = user.metadata.lastSignInTime;
          const lastSignIn = lastSignInTime ? new Date(Date.parse(lastSignInTime)).toISOString() : null;
          posthog.capture('sign_up:email_and_password', {
            $set: {
              last_sign_in: lastSignIn,
            },
          });
        } catch (error) {
          console.error("Posthog error:", error);
        }
      }

      const full_name = first_name + " " + last_name;
      await createUserDocumentFunction({ 
        action: "create_user",
        email, 
        full_name, 
        uid, 
        newsletter_subscribed: newsletter, 
        google_sign_up: false, 
        reqData: req,
        posthog_distinct_id: distinctId === "" ? posthog.get_distinct_id() : distinctId
      });

      // Preserve all current URL parameters
      const currentParams = new URLSearchParams(window.location.search);
      const verifyEmailPath = `/verify-email?${currentParams.toString()}`;
      
      if (user) {
        navigate(verifyEmailPath);
        setLoading(false);
        setFormValid(false);
      } else {
        setLoading(false);
        setFormValid(false);
        console.error("No user found");
      }
    } catch (error) {
      setLoading(false);
      toast.warning(
        "Diese Email ist bereits registriert, bitte nutze eine andere E-Mail-Adresse.",
        {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  /* UseEffect Hooks */
  //
  // // Parse URL Parameters
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    if (email) {
      setFormData((prevData) => ({
        ...prevData,
        email,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check if the user is logged-in on load
  // Only run once
  useEffect(() => {
    checkUserState(auth, navigate, posthog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const signUp = async () => {
      if (formValid) {
        setLoading(true);
        await signUpUser(
          formData.first_name,
          formData.last_name,
          formData.email,
          formData.password,
          formData.newsletter === "on" ? true : false
        );
      }
    };
    signUp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValid, formData]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // should only run once, disable eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading === true) return <SpinningLoader />;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Robowriter KI - Registrieren, um beeindruckende Texte zu generieren</title>
        <meta
          name="description"
          content="Kostenlos Registrieren und erleben wie Robowriter Ihre Text erstellt"
        />
      </Helmet>
      <div className="min-h-screen flex flex-col">
      <NavbarAuth />
      <Container>
        <div className="flex justify-center">
          <section className="flex-1 py-8 flex flex-col items-center justify-center relative mt-4 sm:mt-20 max-w-[551px] w-full">

              {/* Main Title */}
              <div className="text-center mb-6">
                <h1 className="text-primary font-semibold text-3xl mb-2">
                  Konto Erstellen
                </h1>
                <h2 className="text-gray-400 font-poppins">
                  Über 10.000+ Experten vertrauen auf Robowriter
                </h2>
              </div>

              {/* Sign Up Content */}
              {!showEmailForm ? (
                <><div className="w-full">
                  <GoogleSignInButton
                    button_text="Weiter mit Google"
                    auth={auth}
                    provider={provider}
                    navigate={navigate}
                    newsletter_accepted={formData.newsletter === "on"}
                    backgroundColor={"bg-blue-500"}
                    setLoading={setLoading}
                    reqData={req}
                    discount={''}
                    distinctId={distinctId ?? ''}
                    />
                  <div className="flex items-center justify-center my-4">
                    <hr className="flex-1 border-t-2 border-gray-300" />
                    <p className="mx-4 text-gray-500 font-semibold">oder</p>
                    <hr className="flex-1 border-t-2 border-gray-300" />
                  </div>
                  <Button
                    data-testid="email-signup-button"
                    onClick={() => setShowEmailForm(true)}
                    className="mb-4 mt-4 h-12 flex items-center border justify-center  py-4 flex shadow w-full rounded-lg font-Outfit text-lg font-medium bg-white border-2"
                    variant="outline"
                  >
                    <svg
                      className="mr-2"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
                        fill="currentColor"
                      />
                    </svg>
                    <div
                      className="flex items-center block text-base font-bold text-primary"
                    >
                      Weiter mit E-Mail
                    </div>
                  </Button>
                </div>
                  <ul className="flex flex-row flex-wrap">
                  <li className="mb-2">
                        <div className="text-[15px] text-center text-primary mt-4">
                            {`Mit der Registrierung stimmst Du den `}
                            <a
                              target="_blank"
                              href="https://www.iubenda.com/nutzungsbedingungen/91682523"
                              className="ml-1 mr-1 text-primary underline"
                              rel="noreferrer"
                            >
                              {`AGB`}
                            </a>
                            {` und `}
                            <a
                              target="_blank"
                              href="https://www.iubenda.com/privacy-policy/91682523"
                              className="ml-1 mr-1 text-primary underline"
                              rel="noreferrer"
                            >
                              {`Datenschutzbedingungen`}
                            </a>
                            {` von Robowriter zu.`}
                        </div>
                      </li>
                    <li className="block w-full mb-2">
                      <div className="text-[15px] text-center text-primary mt-4">
                        Hast Du bereits ein Konto?
                        <Link 
                          to={appendDistinctIdToUrl(`/sign-in${window.location.search}`, distinctId)} 
                          className="text-secondary"
                        >
                          &nbsp;Login
                        </Link>
                      </div>
                    </li>
                  </ul></>
              ) : (
                <div className="w-full">
                  <Button
                    onClick={() => setShowEmailForm(false)}
                    variant="ghost"
                    className="mb-4"
                  >
                    <ArrowLeft className="mr-2 h-4 w-4" /> Zurück
                  </Button>
                  <form onSubmit={handleSubmit}>
                    <ul className="flex flex-row flex-wrap">
                      <li className="w-full md:max-w-[262px] md:mr-5 mb-3">
                        <div className="">
                          <label htmlFor="first_name" className="mb-1 block text-base font-medium text-primary">
                            Vorname *
                          </label>
                          <input
                            type="text"
                            name="first_name"
                            id="first_name"
                            className="block w-full border-0 p-3 font-light text-primary-500 bg-desertStrom placeholder-gray-500 focus:ring-0 rounded-lg text-sm"
                            placeholder="Vorname"
                            value={formData.first_name}
                            onChange={handleInputChange}
                            required
                          />
                          {(formErrors.first_name.length > 0 && submitted) && (
                            <span className="error mt-2 text-xs block text-red-500">
                              {formErrors.first_name}
                            </span>
                          )}
                        </div>
                      </li>
                      <li className="w-full md:max-w-[262px]">
                        <div className="">
                          <label htmlFor="last_name" className="mb-1 block text-base font-medium text-primary">
                            Nachname *
                          </label>
                          <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            className="block w-full border-0 p-3 font-light text-primary-500 bg-desertStrom placeholder-gray-500 focus:ring-0 rounded-lg text-sm"
                            placeholder="Nachname"

                            value={formData.last_name}
                            onChange={handleInputChange}
                            required
                          />
                          {formErrors.last_name.length > 0 && (
                            <span className="error mt-2 text-xs block text-red-500">
                              {formErrors.last_name}
                            </span>
                          )}
                        </div>
                      </li>
                      <li className="w-full mt-2 mb-2">
                        <div className="">
                          <label
                            htmlFor="email"
                            className="mb-1 block text-base font-medium text-primary"
                          >
                            E-Mail-Adresse *
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="block w-full border-0 p-3 font-light text-primary-500 bg-desertStrom placeholder-gray-500 focus:ring-0 rounded-lg text-sm"
                            placeholder="E-Mail-Adresse"
                            required
                            value={formData.email}
                            onChange={handleInputChange}
                          />
                          {(formErrors.email.length > 0 && submitted) && (
                            <span className="error mt-2 text-xs block text-red-500">
                              {formErrors.email}
                            </span>
                          )}
                        </div>
                      </li>
                      <li className="w-full mb-2">
                        <div className="">
                          <label
                            htmlFor="password"
                            className="mb-1 block text-base font-medium text-primary"
                          >
                            Passwort *
                          </label>
                          <div className="relative">
                            <input
                              type={isShowPassword ? "text" : "password"}
                              name="password"
                              id="password"
                              className="block w-full border-0 p-3 font-light text-primary-500 bg-desertStrom placeholder-gray-500 focus:ring-0 rounded-lg text-sm"
                              placeholder="********"
                              required
                              value={formData.password}
                              onChange={handleInputChange}
                            />
                            <span
                              onClick={() => setShowPassword(!isShowPassword)}
                              className="absolute right-3 top-1/2 -translate-y-1/2"
                            >
                              {isShowPassword ? <ClosedEye /> : <Eye />}
                            </span>
                          </div>
                          {(formErrors.password.length > 0 && submitted) && (
                            <span className="error mt-2 text-xs block text-red-500">
                              {formErrors.password}
                            </span>
                          )}
                        </div>
                      </li>
                      <li className={`w-full mb-4`}>
                        <div className="grid grid-cols-2 grid-rows-2 gap-1 lg:max-w-full">
                          <div className="flex gap-1">
                            <div className="flex justify-center items-center">
                              {len8Ok ?
                                <div
                                  className={`h-1 w-1 text-white bg-green-500 rounded-full p-2 inline-flex items-center justify-center`}>
                                  <FontAwesomeIcon className="h-3 w-3" icon={faCheck} />
                                </div>
                                :
                                <div className="w-1 h-1 bg-gray-500 rounded-full"></div>
                              }
                              <p className={`text-sm ml-2 text-gray-500 font-light`}> Mindestens 8 Zeichen
                              </p>
                            </div>
                          </div>

                          <div className="flex gap-1">
                            <div className="flex justify-center items-center">
                              {specialCharOk ?
                                <div
                                  className={`h-1 w-1 text-white bg-green-500 rounded-full p-2 inline-flex items-center justify-center`}>
                                  <FontAwesomeIcon className="h-3 w-3" icon={faCheck} />
                                </div>
                                :
                                <div className="w-1 h-1 bg-gray-500 rounded-full"></div>
                              }
                              <p className={`text-sm ml-2 text-gray-500 font-light`}>Mindestens ein Sonderzeichen
                              </p>
                            </div>
                          </div>

                          <div className="flex gap-1">
                            <div className="flex justify-center items-center">
                              {largeLetterOk ?
                                <div
                                  className={`h-1 w-1 text-white bg-green-500 rounded-full p-2 inline-flex items-center justify-center`}>
                                  <FontAwesomeIcon className="h-3 w-3" icon={faCheck} />
                                </div>
                                :
                                <div className="w-1 h-1 bg-gray-500 rounded-full"></div>

                              }
                              <p className={'text-sm ml-2 text-gray-500 font-light'}>Mindestens ein großer
                                Buchstabe</p>
                            </div>
                          </div>

                          <div className="flex gap-1">
                            <div className="flex justify-center items-center">
                              {numberOk ?
                                <div
                                  className={`h-1 w-1 text-white bg-green-500 rounded-full p-2 inline-flex items-center justify-center`}>
                                  <FontAwesomeIcon className="h-3 w-3" icon={faCheck} />
                                </div>
                                :
                                <div className="w-1 h-1 bg-gray-500 rounded-full"></div>
                              }
                              <p className={`text-sm ml-2 text-gray-500 font-light`}>Mindestens eine Ziffer</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-start sm:items-center">
                          <input
                            type="checkbox"
                            name="newsletter"
                            id="newsletter"
                            className="w-4 h-4 text-primary-500 focus:ring-primary-500 border-gray-300 rounded-full mt-1 sm:mt-0"
                            onChange={handleInputChange}
                            data-testid="newsletter-checkbox"
                          />
                          <label
                            htmlFor="newsletter"
                            className="ml-1 sm:flex text-sm text-gray-400"
                            style={{ whiteSpace: "pre-wrap" }}
                            data-testid="newsletter-label">
                            Ich möchte über Rabatte, Angebote und andere Updates per E-Mail informiert werden.
                          </label>
                        </div>
                      </li>
                      <li className="w-full flex justify-center md:my-8 my-7 mb-2">
                        <button
                          type="submit"
                          className="btn-default w-full rounded-lg font-Outfit bg-secondary text-lg h-12 text-white font-bold tracking-[0.16px] border-2 border-secondary transition-all duration-200 ease-in-out px-8 hover:bg-transparent hover:text-secondary"
                        >
                          Konto erstellen
                        </button>
                      </li>
                    </ul>
                  </form>
                </div>
              )}
              </section>
        </div>
      </Container>
      <Footer />
    </div>
    </div>
  );
};

export default SignUpPage;
