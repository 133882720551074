import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { ScrollArea } from "../../components/ui/scroll-area";
import { ChevronRight, Search, X, Plus } from "lucide-react";
import { useState, useEffect } from "react";
import promptsData from './prompts.json';
import { auth } from "../../config/fbConfig";
import firebase from "../../config/fbConfig";
import { Textarea } from "../../components/ui/textarea";
import { Separator } from "../../components/ui/separator";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../components/ui/alert-dialog";
import { Loader2 } from "lucide-react";

// Update interfaces to match new structure
interface CustomPrompt {
  id: string;
  name: string;
  prompt: string;
  created_at: Date;
}

interface UseCase {
  id: string;
  name: string;
  prompt: string;
}

interface PromptLibraryProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectPrompt: (prompt: string) => void;
}

export function PromptLibrary({ isOpen, onClose, onSelectPrompt }: PromptLibraryProps) {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedUseCase, setSelectedUseCase] = useState<UseCase | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [customPrompts, setCustomPrompts] = useState<CustomPrompt[]>([]);
  const [isCreatingPrompt, setIsCreatingPrompt] = useState(false);
  const [newPromptName, setNewPromptName] = useState("");
  const [newPromptText, setNewPromptText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [promptToDelete, setPromptToDelete] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  // Transform the JSON data into a more usable format
  const categories = ["Benutzerdefiniert", ...Object.keys(promptsData)];
  
  // Load custom prompts from Firestore
  useEffect(() => {
    if (!auth.currentUser) return;

    const promptsRef = firebase.firestore()
      .collection('users')
      .doc(auth.currentUser.uid)
      .collection('prompts');

    const unsubscribe = promptsRef.orderBy('created_at', 'desc').onSnapshot(
      snapshot => {
        const prompts = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          created_at: doc.data().created_at?.toDate()
        })) as CustomPrompt[];
        setCustomPrompts(prompts);
      },
      error => {
        console.error("Error loading custom prompts:", error);
        // Set empty array if there's an error (like collection doesn't exist)
        setCustomPrompts([]);
      }
    );

    return () => unsubscribe();
  }, []);

  // When component mounts or when dialog opens, select Custom category by default
  useEffect(() => {
    if (isOpen) {
      setSelectedCategory("Benutzerdefiniert");
    }
  }, [isOpen]);

  const getUseCases = (categoryName: string) => {
    if (categoryName === "Benutzerdefiniert") {
      return customPrompts.map(prompt => ({
        id: prompt.id,
        name: prompt.name,
        prompt: prompt.prompt
      }));
    }
    
    const category = promptsData[categoryName as keyof typeof promptsData];
    if (!category) return [];
    
    return Object.entries(category).map(([name, prompt]) => ({
      id: name.toLowerCase().replace(/\s+/g, '-'),
      name,
      prompt
    }));
  };

  // Filter logic update to always include Custom category
  const filteredCategories = categories.filter(category => {
    if (category === "Benutzerdefiniert") {
      // Always show Custom category
      return true;
    }
    const matchesCategory = category.toLowerCase().includes(searchQuery.toLowerCase());
    const useCases = getUseCases(category);
    const matchesUseCase = useCases.some(useCase => 
      useCase.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return matchesCategory || matchesUseCase;
  });

  const handleSavePrompt = async () => {
    if (!auth.currentUser || !newPromptName.trim() || !newPromptText.trim()) return;

    setIsSaving(true);
    const promptData = {
      name: newPromptName,
      prompt: newPromptText,
      created_at: firebase.firestore.FieldValue.serverTimestamp()
    };

    try {
      const docRef = await firebase.firestore()
        .collection('users')
        .doc(auth.currentUser.uid)
        .collection('prompts')
        .add(promptData);
      
      // Get the newly created document
      const newDoc = await docRef.get();
      const newPrompt = {
        id: newDoc.id,
        ...newDoc.data()
      } as UseCase;

      // Update the selected prompt
      setSelectedUseCase(newPrompt);
      setIsCreatingPrompt(false);
      setNewPromptName("");
      setNewPromptText("");
    } catch (error) {
      console.error("Error saving prompt:", error);
      if (error instanceof Error) {
        console.error("Error details:", error.message);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdatePrompt = async () => {
    if (!auth.currentUser || !selectedUseCase) return;

    setIsSaving(true);
    const updateData = {
      name: newPromptName,
      prompt: newPromptText
    };

    try {
      const docRef = firebase.firestore()
        .collection('users')
        .doc(auth.currentUser.uid)
        .collection('prompts')
        .doc(selectedUseCase.id);

      await docRef.update(updateData);
      
      // Get the updated document
      const updatedDoc = await docRef.get();
      const updatedPrompt = {
        id: updatedDoc.id,
        ...updatedDoc.data()
      } as UseCase;

      // Update the selected prompt
      setSelectedUseCase(updatedPrompt);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating prompt:", error);
      if (error instanceof Error) {
        console.error("Error details:", error.message);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeletePrompt = async (promptId: string) => {
    if (!auth.currentUser) return;

    setIsSaving(true);
    try {
      await firebase.firestore()
        .collection('users')
        .doc(auth.currentUser.uid)
        .collection('prompts')
        .doc(promptId)
        .delete();

      // Clear selection and reset states
      setSelectedUseCase(null);
      setPromptToDelete(null);
      setIsEditing(false);
      setNewPromptName("");
      setNewPromptText("");
    } catch (error) {
      console.error("Error deleting prompt:", error);
    } finally {
      setIsSaving(false);
    }
  };

  // Update the click handler for categories to also reset the selected use case
  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
    setSelectedUseCase(null); // Clear the selected use case
    setIsCreatingPrompt(false); // Also reset creation mode if active
    setIsEditing(false); // Reset editing mode if active
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl h-[80vh] flex flex-col p-0 bg-white">
        <DialogHeader className="px-6 py-4 border-b">
          <div className="flex items-center justify-start">
            <DialogTitle className="text-xl font-semibold">Prompt-Bibliothek</DialogTitle>
            <div className="flex items-center space-x-4 pl-8">
              <div className="flex items-center space-x-2 w-64">
                <Search className="h-4 w-4 text-gray-400" />
                <Input
                  placeholder="Prompts durchsuchen..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="h-9 text-base focus-visible:ring-0 focus-visible:ring-offset-0"
                />
              </div>
            </div>
          </div>
        </DialogHeader>

        <div className="flex-1 flex overflow-hidden">
          {/* Categories */}
          <div className="w-64 border-r">
            <ScrollArea className="h-full">
              {filteredCategories.map((category, index) => (
                <div key={category}>
                  <button
                    onClick={() => handleCategorySelect(category)}
                    className={`flex items-center justify-between w-full px-6 py-3 text-base hover:bg-[#9C68EF1A] ${
                      selectedCategory === category ? 'bg-[#9C68EF1A]' : ''
                    }`}
                  >
                    <span>{category}</span>
                    <ChevronRight className="h-4 w-4" />
                  </button>
                  {category === "Benutzerdefiniert" && <Separator />}
                </div>
              ))}
            </ScrollArea>
          </div>

          {/* Use Cases */}
          <div className="w-64 border-r">
            <ScrollArea className="h-full">
              {selectedCategory === "Benutzerdefiniert" && (
                <button
                  onClick={() => {
                    setIsCreatingPrompt(true);
                    setSelectedUseCase(null);
                  }}
                  className="w-full px-6 py-3 text-base text-left hover:bg-[#9C68EF1A] flex items-center gap-2 text-primary"
                >
                  <Plus className="h-4 w-4" />
                  Eigenen Prompt erstellen
                </button>
              )}
              
              {isCreatingPrompt && selectedCategory === "Benutzerdefiniert" && (
                <div className="px-6 py-3">
                  <Input
                    placeholder="Titel"
                    value={newPromptName}
                    onChange={(e) => setNewPromptName(e.target.value)}
                    className="mb-2"
                  />
                </div>
              )}

              {selectedCategory && 
                getUseCases(selectedCategory).map((useCase) => (
                  <div key={useCase.id} className="group relative">
                    <button
                      onClick={() => {
                        setSelectedUseCase(useCase);
                        setNewPromptText(useCase.prompt);
                      }}
                      className={`w-full px-6 py-3 text-base text-left hover:bg-[#9C68EF1A] ${
                        selectedUseCase?.id === useCase.id ? 'bg-[#9C68EF1A]' : ''
                      }`}
                    >
                      {useCase.name}
                    </button>
                    {selectedCategory === "Benutzerdefiniert" && isEditing && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setPromptToDelete(useCase.id);
                        }}
                        className="absolute right-2 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 p-1 hover:bg-red-100 rounded"
                      >
                        <X className="h-4 w-4 text-red-500" />
                      </button>
                    )}
                  </div>
                ))
              }
            </ScrollArea>
          </div>

          {/* Prompt Details */}
          <div className="flex-1 flex flex-col h-full">
            {isCreatingPrompt ? (
              <>
                <div className="flex-1 p-6 space-y-4">
                  <h3 className="text-xl font-semibold">Benutzerdefinierten Prompt erstellen</h3>
                  <Textarea
                    placeholder="Prompt eingeben... Nutze [eckige Klammern], um Variablen zu definieren."
                    value={newPromptText}
                    onChange={(e) => setNewPromptText(e.target.value)}
                    className="min-h-[200px]"
                  />
                </div>
                <div className="p-4 border-t flex justify-end gap-2">
                  <Button
                    variant="outline"
                    onClick={() => {
                      setIsCreatingPrompt(false);
                      setNewPromptName("");
                      setNewPromptText("");
                    }}
                  >
                    Abbrechen
                  </Button>
                  <Button 
                    onClick={handleSavePrompt}
                    className="bg-[#9C68EF] hover:bg-[#8656D1] text-white"
                    disabled={isSaving}
                  >
                    {isSaving ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Speichern...
                      </>
                    ) : (
                      'Prompt speichern'
                    )}
                  </Button>
                </div>
              </>
            ) : selectedUseCase ? (
              <>
                <div className="flex-1 p-6 space-y-4">
                  {selectedCategory === "Benutzerdefiniert" && isEditing ? (
                    <Input
                      value={newPromptName}
                      onChange={(e) => setNewPromptName(e.target.value)}
                      className="text-xl font-semibold"
                    />
                  ) : (
                    <h3 className="text-xl font-semibold">{selectedUseCase.name}</h3>
                  )}
                  {selectedCategory === "Benutzerdefiniert" && isEditing ? (
                    <Textarea
                      value={newPromptText}
                      onChange={(e) => setNewPromptText(e.target.value)}
                      className="min-h-[200px]"
                    />
                  ) : (
                    <div className="rounded-lg border p-4 bg-gray-50">
                      <p className="text-base whitespace-pre-wrap">{selectedUseCase.prompt}</p>
                    </div>
                  )}
                </div>
                <div className="p-4 border-t flex justify-between gap-2">
                  {selectedCategory === "Benutzerdefiniert" && isEditing ? (
                    <>
                      <Button
                        variant="outline"
                        onClick={() => setIsEditing(false)}
                      >
                        Abbrechen
                      </Button>
                      <Button 
                        onClick={handleUpdatePrompt}
                        className="bg-[#9C68EF] hover:bg-[#8656D1] text-white"
                        disabled={isSaving}
                      >
                        {isSaving ? (
                          <>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            Speichern...
                          </>
                        ) : (
                          'Änderungen speichern'
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      <div>
                        {selectedCategory === "Benutzerdefiniert" && (
                          <Button
                            variant="outline"
                            onClick={() => {
                              setIsEditing(true);
                              setNewPromptText(selectedUseCase.prompt);
                              setNewPromptName(selectedUseCase.name);
                            }}
                          >
                            Bearbeiten
                          </Button>
                        )}
                      </div>
                      <Button 
                        onClick={() => {
                          onSelectPrompt(selectedUseCase.prompt);
                          onClose();
                        }}
                        className="bg-[#9C68EF] hover:bg-[#8656D1] text-white"
                      >
                        Prompt nutzen
                      </Button>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className="h-full flex items-center justify-center text-gray-500 text-base p-6">
                Wähle einen Anwendungsfall, um den Prompt zu sehen
              </div>
            )}
          </div>
        </div>
      </DialogContent>
      <AlertDialog open={!!promptToDelete} onOpenChange={() => setPromptToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Bist du sicher?</AlertDialogTitle>
            <AlertDialogDescription>
              Diese Aktion kann nicht rückgängig gemacht werden. Dies wird den Prompt permanent löschen.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Abbrechen</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => promptToDelete && handleDeletePrompt(promptToDelete)}
              className="bg-red-500 hover:bg-red-600 text-white"
            >
              Löschen
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Dialog>
  );
}