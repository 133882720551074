import { Link, useNavigate } from "react-router-dom";
import MiniSpinningLoader from "../loader/mini_spinning_loader";
// import Dropdown from "./Dropdown";
import {
  firestore,
  collection,
  doc,
  setDoc,
  getDoc,
  auth,
  functions
} from "../../config/fbConfig";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { MoreVertical, ChevronRight } from 'lucide-react';
import { useUserData } from "../../providers/UserDataProvider";
import { httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import { useState } from "react";

type Props = {
    id: string
    title: string;
    description: string;
    iconName: string;
    type: string;
    iconColor: string;
    favorite?: boolean;
    disableLink?: boolean;
    loading?: boolean;
    locked?: boolean;
    onEdit?: () => void;
    onDelete?: () => void;
    sharedWithOrgs?: string[];
    organizationId?: string;
    organizationName?: string;
    originalOwnerId?: string
};

const TemplateCard: React.FC<{ items: Props, pinIconDisabled?: boolean }> = ({ items, pinIconDisabled = false }) => {
    const param = decodeURIComponent(items.title).replace(/\s/g, "-");
    const pathname = `/templates/${param}`;
    const navigate = useNavigate();
    const { organizations } = useUserData();
    const [isSharing, setIsSharing] = useState<string | null>(null);
    const [isUnsharing, setIsUnsharing] = useState(false);
    
    const clickPin = async () => {
        if (auth.currentUser!.uid) {
          // build user & document reference
          const userDocRef = doc(collection(firestore, "users"), auth.currentUser!.uid);
          const writeDataDocRef = doc(collection(userDocRef, "writedata"), 'appdata');
          const allwriteDataRef = await getDoc(writeDataDocRef);
          let allwriteData = allwriteDataRef.data() || {};

          // check if writeDataDocRef exists
          getDoc(writeDataDocRef)
            .then((docSnap) => {
              let templates: any = [];

              if (docSnap.exists()) {
                let data = docSnap.data();
                if (data.hasOwnProperty('pinned_templates')) {
                    templates = data.pinned_templates;
                } else {
                    templates.push(items.id);
                    data.pinned_templates = templates
                    return setDoc(writeDataDocRef, data);
                }
              } else {
                // Create a document if doesn't exists with initial data
                templates.push(items.id);
                return setDoc(writeDataDocRef, { pinned_templates: templates });
              }

              return templates;
            })
            .then((templates) => {
              // check if "items.id" is already in the "pinned_templates"
              const index = templates.indexOf(items.id);

              if (index === -1) {
                // itemID is not in the array, insert it
                templates.push(items.id);
              } else {
                // itemID is in the pinned_templates, remove it
                templates.splice(index, 1);
              }

              // Update "pinned_templates"
              allwriteData.pinned_templates = templates
              return setDoc(writeDataDocRef, allwriteData);
            })
            .then(() => {
              // Document successfully updated!
            })
            .catch((error) => {
              // console.error("Error updating document: ", error);
            });
        }

    };

    const handleDelete = () => {
      if (items.onDelete) {
        items.onDelete();
      }
    };

    const handleShare = async (orgId: string) => {
        if (!auth.currentUser?.uid) return;
        
        setIsSharing(orgId);
        const shareTemplate = httpsCallable(functions, 'share_template_with_organization');
        
        try {
            await shareTemplate({
                templateId: items.id,
                organizationId: orgId
            });
            
            toast.success(`Vorlage erfolgreich mit Organisation geteilt`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            
        } catch (error) {
            console.error("Error sharing template:", error);
            toast.error("Fehler beim Teilen der Vorlage. Bitte versuchen Sie es erneut.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setIsSharing(null);
        }
    };

    const handleUnshare = async (orgId: string) => {
        if (!auth.currentUser?.uid) return;
        
        setIsUnsharing(true);
        const unshareTemplate = httpsCallable(functions, 'unshare_template_from_organization');
        try {
            const result = await unshareTemplate({
                templateId: items.id.split("_").pop() || items.id,
                organizationId: orgId
            });
            toast.success(`Vorlage erfolgreich von Organisation entfernt`);
        } catch (error) {
            console.error("Error unsharing template:", error);
            toast.error("Fehler beim Entfernen der Vorlage. Bitte versuchen Sie es erneut.");
        } finally {
            setIsUnsharing(false);
        }
    };

    const cardContent = (
        <>
            <div className="relative">
                <div>
                    <div className="w-full flex items-center">

                    <span className="block h-9 w-9 mb-1 sm:mb-4">
                        <span className={`inline-flex items-center p-1 justify-center h-full w-full
                         bg-gradient-to-tr from-[#C06EEC1A] to-[#1754FC1A] rounded-full ${items.loading ? 'bg-gray-500 opacity-50' : ''}`}>
                            <i className={`${items.iconName} inline-flex items-center justify-center w-auto h-auto text-lg bg-gradient-to-tr from-[#C06EEC] to-[#1754FC] bg-clip-text text-transparent `}
                            ></i>
                        </span>
                    </span>
                        {(items.type === "Persönliche Vorlagen" || items.type === "Organisation") && (
                        <div className="ml-4 w-28 h-6 mb-1 sm:mb-4 flex items-center justify-center hidden md:block">
                            <div className="w-full m-2 h-full text-center bg-gradient-to-tr from-[#C06EEC] to-[#1754FC] rounded text-white text-xs flex items-center justify-center">
                                {items.type === "Persönliche Vorlagen" ? "Eigene Vorlage" : (
                                    <div className="flex items-center gap-2" data-testid="organization-name-container">
                                        {items.organizationName}
                                        {isSharing === items.organizationId && (
                                            <div className="h-3 w-3">
                                                <MiniSpinningLoader />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        )}
                    </div>

                    <h4 data-testid="templatespage-template-title" className={`mb-1 sm:mb-2 md:text-base text-xs font-semibold line-clamp-2 ${items.loading ? 'text-gray-500 opacity-50' : ''}`}>{items.title}</h4>
                    <p className={`md:text-[11px] text-[10px] font-poppins text-gray-8000 md:line-clamp-3 line-clamp-5 ${items.loading ? 'text-gray-500 opacity-50' : ''}`}>{items.description}</p>
                </div>
            </div>

            {/* Locked Template Card */}
            {items.locked && (
                <div className="inset-0 absolute bg-black/50 z-10 flex items-center justify-center p-4">
                    <svg className=" md:w-20 md:h-20 h-11 w-11" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M67.2917 35.4167H70.8333C71.7726 35.4167 72.6735 35.7898 73.3377 36.454C74.0019 37.1182 74.375 38.019 74.375 38.9583V74.375C74.375 75.3143 74.0019 76.2151 73.3377 76.8793C72.6735 77.5435 71.7726 77.9167 70.8333 77.9167H14.1667C13.2274 77.9167 12.3265 77.5435 11.6623 76.8793C10.9981 76.2151 10.625 75.3143 10.625 74.375V38.9583C10.625 38.019 10.9981 37.1182 11.6623 36.454C12.3265 35.7898 13.2274 35.4167 14.1667 35.4167H17.7083V31.875C17.7083 25.2998 20.3203 18.994 24.9696 14.3446C29.619 9.69531 35.9248 7.08334 42.5 7.08334C49.0752 7.08334 55.381 9.69531 60.0304 14.3446C64.6797 18.994 67.2917 25.2998 67.2917 31.875V35.4167ZM60.2083 35.4167V31.875C60.2083 27.1785 58.3426 22.6743 55.0217 19.3533C51.7007 16.0324 47.1965 14.1667 42.5 14.1667C37.8035 14.1667 33.2993 16.0324 29.9783 19.3533C26.6574 22.6743 24.7917 27.1785 24.7917 31.875V35.4167H60.2083ZM38.9583 49.5833V63.75H46.0417V49.5833H38.9583Z" fill="white" />
                    </svg>

                </div>)
            }
            {isUnsharing && (
                <div className="absolute bottom-2 right-2">
                    <div className="h-4 w-4">
                        <MiniSpinningLoader />
                    </div>
                </div>
            )}
        </>
    )
        ;

    return items.disableLink ? (
        <div
            className="block fade-in h-full bg-white text-primary-500 border border-gray-300 shadow p-4 transition-transform duration-300 hover:scale-95 relative">
            {cardContent}
            <div role="status"
                 className="text-center z-10 absolute inset-0 flex items-center justify-center m-8 md:m-12">
                <MiniSpinningLoader/>
            </div>
        </div>
    ) : (
        <div className="relative transition-transform duration-300 hover:scale-95 h-full group">
            <div className="absolute top-4 right-4 z-[1]">
                <DropdownMenu>
                    <DropdownMenuTrigger className="hover:bg-gray-100 p-2 rounded-md inline-flex items-center justify-center">
                        <MoreVertical className={`h-4 w-4 pointer-events-none ${items.favorite ? 'text-secondary-500' : ''}`} />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="bg-white" align="end">
                        <DropdownMenuItem
                            className="hover:bg-purple-50 cursor-pointer"
                            onClick={() => clickPin()}
                        >
                            {items.favorite ? 'Entfavorisieren' : 'Favorisieren'}
                        </DropdownMenuItem>
                        
                        {items.type === "Persönliche Vorlagen" && (
                            <>
                                <DropdownMenuItem
                                    className="hover:bg-purple-50 cursor-pointer"
                                    onClick={() => navigate(`/edit/${items.id}`, { 
                                        state: {
                                            ...items,
                                            onEdit: undefined,
                                            onDelete: undefined
                                        }
                                    })}
                                >
                                    Bearbeiten
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                    className="text-red-600 hover:bg-red-50 hover:text-red-700 cursor-pointer"
                                    onClick={handleDelete}
                                >
                                    Löschen
                                </DropdownMenuItem>
                                
                                {organizations.length > 0 && (
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                            <DropdownMenuItem 
                                                className="hover:bg-purple-50"
                                                data-testid="share-with-org-trigger"
                                                onSelect={(e) => e.preventDefault()}
                                            >
                                                <div className="w-full flex items-center justify-between">
                                                    Mit Organisation teilen
                                                    <ChevronRight className="h-4 w-4" />
                                                </div>
                                            </DropdownMenuItem>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent
                                            className="bg-white"
                                            side="right"
                                            sideOffset={-5}
                                            data-testid="share-with-org-content"
                                        >
                                            {organizations.map((org) => (
                                                <DropdownMenuItem
                                                    key={org.id}
                                                    className="hover:bg-purple-50 cursor-pointer"
                                                    onSelect={(e) => {
                                                        e.preventDefault();
                                                        handleShare(org.id);
                                                    }}
                                                    disabled={items.sharedWithOrgs?.includes(org.id) || isSharing === org.id}
                                                    data-testid={`share-with-org-${org.name}`}
                                                >
                                                    {isSharing === org.id ? (
                                                        <div className="flex items-center">
                                                            <span className="mr-2">Teilen...</span>
                                                            <div className="h-3 w-3">
                                                                <MiniSpinningLoader />
                                                            </div>
                                                            </div>
                                                        ) : (
                                                        <>
                                                            {org.name}
                                                            {items.sharedWithOrgs?.includes(org.id) && 
                                                                <span className="ml-2 text-gray-500">(Geteilt)</span>
                                                            }
                                                        </>
                                                    )}
                                                </DropdownMenuItem>
                                            ))}
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                )}
                            </>
                        )}
                        {items.type === "Organisation" && 
                            organizations.some(org => 
                                org.id === items.organizationId && 
                                (items.originalOwnerId === auth.currentUser?.uid || 
                                 org.members[auth.currentUser?.uid || ""]?.role === 'admin')
                            ) && (
                                <DropdownMenuItem
                                    data-testid={`unshare-org-${items.organizationName}`}
                                    className="hover:bg-purple-50 cursor-pointer"
                                    onClick={() => handleUnshare(items.organizationId!)}
                                >
                                    Nicht mehr teilen
                                </DropdownMenuItem>
                            )
                        }
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>

            <Link
                to={pathname}
                state={{
                    ...items,
                    onEdit: undefined,
                    onDelete: undefined
                }}
                className={`flex flex-col relative md:min-h-[187px] fade-in h-full border-[1px] border-transparent
        ${(!items.favorite ? !items.favorite : false) ? ' bg-gradient-to-tr from-gradientColor-1000 to-gradientColor-2000' : ' border-gray-300'} text-primary-500 shadow-md rounded-[4px]`}>
                <div
                    className="bg-white w-full h-full pr-[1px] md:px-[19px] md:pt-[18px] md:pb-[22px] p-3.5 rounded-[4px]">
                    {cardContent}
                </div>
            </Link>
        </div>

    );
};

export default TemplateCard;
