import { Card, CardContent, CardHeader } from "~/components/ui/card"
import { Separator } from "~/components/ui/separator"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "~/components/ui/table"

export default function OrganizationManagerSkeleton() {
  return (
    <Card>
      <CardHeader>
        <div className="h-7 w-56 bg-muted/60 rounded-md animate-pulse" />
        <div className="h-5 w-[480px] bg-muted/60 rounded-md animate-pulse mt-2.5" />
      </CardHeader>
      <CardContent>
        <div className="space-y-8">
          {/* Organization Name */}
          <div className="space-y-2.5">
            <div className="h-4 w-28 bg-muted/60 rounded animate-pulse" />
            <div className="h-7 w-64 bg-muted/80 rounded-md animate-pulse" />
          </div>

          {/* Role */}
          <div className="space-y-2.5">
            <div className="h-4 w-20 bg-muted/60 rounded animate-pulse" />
            <div className="h-5 w-36 bg-muted/80 rounded-md animate-pulse" />
          </div>

          <Separator className="my-6" />

          {/* Members Table */}
          <div className="space-y-4">
            <div className="h-4 w-20 bg-muted/60 rounded animate-pulse" />
            <div className="border rounded-lg overflow-hidden">
              <Table>
                <TableHeader>
                  <TableRow className="hover:bg-transparent">
                    <TableHead className="w-[200px]">
                      <div className="h-4 w-16 bg-muted/60 rounded animate-pulse" />
                    </TableHead>
                    <TableHead className="w-[300px]">
                      <div className="h-4 w-24 bg-muted/60 rounded animate-pulse" />
                    </TableHead>
                    <TableHead>
                      <div className="h-4 w-16 bg-muted/60 rounded animate-pulse" />
                    </TableHead>
                    <TableHead className="w-[50px]" />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {[1, 2, 3].map((i) => (
                    <TableRow key={i} className="hover:bg-transparent">
                      <TableCell>
                        <div className="h-5 w-36 bg-muted/60 rounded animate-pulse" />
                      </TableCell>
                      <TableCell>
                        <div className="h-5 w-52 bg-muted/60 rounded animate-pulse" />
                      </TableCell>
                      <TableCell>
                        <div className="h-6 w-24 bg-muted/40 rounded-full animate-pulse" />
                      </TableCell>
                      <TableCell>
                        <div className="h-8 w-8 bg-muted/40 rounded-md animate-pulse ml-auto" />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

            {/* Invite Form Skeleton */}
            <div className="pt-6">
              <Separator className="mb-6" />
              <div className="space-y-6">
                <div className="space-y-2.5">
                  <div className="h-4 w-36 bg-muted/60 rounded animate-pulse" />
                  <div className="h-10 w-full bg-muted/60 rounded-md animate-pulse" />
                </div>
                <div className="h-10 w-full bg-muted/40 rounded-md animate-pulse" />
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
} 