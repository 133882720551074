import { Link } from "react-router-dom";
import Container from "../components/Container";

const FooterInside = () => {
  return (
    <footer className="mt-auto">
      <Container>
        <div className="py-6">
          <div className="flex flex-col md:flex-row flex-wrap items-center justify-between gap-4 text-sm font-roboto">
            <p>Robowriter © Copyright 2025</p>
            <span className="h-10 w-10">
              <img
                className="block h-full w-full object-contain"
                src="/assets/images/logo-0.svg"
                alt="logo-white"
              />
            </span>
            <div className="flex items-center gap-3">
              <Link to="/imprint" className=" hover:text-secondary-500">
                Impressum
              </Link>
              <span className="py-2 border-r-2 border-gray-500" />
              <Link
                to="https://www.iubenda.com/privacy-policy/91682523"
                className=" hover:text-secondary-500"
              >
                Datenschutzerklärung
              </Link>
              {/* <span className="py-2 border-r-2 border-gray-500" /> */}
              {/* <Link to="" className=" hover:text-secondary-500 ">
                  Privacy Policy
                </Link> */}
              <span className="py-2 border-r-2 border-gray-500" />
              <Link
                to="https://www.iubenda.com/nutzungsbedingungen/91682523"
                className=" hover:text-secondary-500"
              >
                AGBs
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default FooterInside;
