import { LoaderIcon } from 'lucide-react';
import React from 'react';
import { BackIcon, PersonaIcon } from "~/assets/Icons";

interface PersonaFormProps {
    name: string;
    input: string;
    handleNameFieldChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleTextFieldChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    submitNewPersona: (event: React.FormEvent) => void;
    handleBackToContent: () => void;
    handleAnalyze: (event: React.FormEvent) => void;
    isEditing: boolean;
    isDefault: boolean;
    handleToggleChange: () => void;
    isAnalyzing: boolean;
    analyzeError: string;
}

const PersonaForm: React.FC<PersonaFormProps> = ({
    name,
    input,
    handleNameFieldChange,
    handleTextFieldChange,
    submitNewPersona,
    handleBackToContent,
    handleAnalyze,
    isEditing,
    isDefault,
    handleToggleChange,
    isAnalyzing,
    analyzeError
}) => {
    return (
        <div className="bg-white w-full">
            <div className="mx-auto items-center justify-center p-6 md:px-[52px] pt-8 pb-7">
                <div className="flex items-center cursor-pointer" onClick={handleBackToContent}>
                    <BackIcon />
                    <span className="ms-4 text-[16px] sm:text-[18px] font-medium leading-[23px]">Zurück</span>
                </div>
                <h3 className="text-[24px] md:text-[32px] mt-4 font-outfit font-semibold text-textColor">
                    {isEditing ? 'Tonfall bearbeiten' : 'Tonfall hinzufügen'}
                </h3>
                <div className="mt-4 w-full justify-center">
                    <label htmlFor="theme" className="mt-8 text-base font-normal leading-6 tracking-[0%] text-left text-[#808080] mb-2">
                        Name
                    </label>
                    <div className="flex flex-col mb-1 mt-2">
                        <textarea
                            data-testid="tonfall-name-input"
                            value={name}
                            onChange={handleNameFieldChange}
                            placeholder=""
                            className="rounded-[8px] bg-[#20235608] placeholder:text-xs placeholder:text-gray-700 border-0 px-3 w-full lg:w-[60%] resize-none h-[2.5em] leading-[2.5em] overflow-hidden"
                            style={{
                                paddingTop: 'calc((1.5em - 1em) / 2)',
                                paddingBottom: 'calc((1.5em - 1em) / 2)',
                            }}
                        />
                    </div>
                    <div className="flex flex-col mb-3">
                        <div className="w-full flex justify-between">
                            <label htmlFor="theme" className="mt-3 text-base font-normal leading-6 tracking-[0%] text-left text-[#808080] mb-2">
                                Tonfall
                            </label>
                        </div>
                        <textarea
                            data-testid="tonfall-text-input"
                            value={input}
                            maxLength={10000}
                            onChange={handleTextFieldChange}
                            rows={10}
                            placeholder=""
                            className="rounded-[8px] bg-[#20235608] placeholder:text-xs placeholder:text-gray-700 border-0 w-full lg:w-[60%] py-2 px-3 mb-2 resize-none"
                        />
                    </div>
                    <div className="rounded-[8px] bg-[#9C68EF0D] w-full lg:w-[60%] py-[20px] px-[32px]">
                        <div className="flex items-center">
                            <img src="/assets/images/tonfall-light.png" alt="icon" className="mr-2 w-[24px] h-[24px]" />
                            <h4 className="text-[#9C68EF] font-semibold text-lg text-[19px] font-semibold leading-[29px] tracking-[0%]">
                                Hinweise zur Bearbeitung des Tonfalls
                            </h4>
                        </div>
                        <div className="mt-3 w-[90%] text-[15px] text-[#808080]">
                            <p className="leading-[21px]">1. Achte bei der Bearbeitung darauf, keine negativen Befehle zu verwenden (z. B. verwende „sei höflich" anstelle von „sei nicht unhöflich").</p>
                            <p className="mt-2 leading-[21px]">2. Die besten Ergebnisse erzielst Du, wenn Du die Analyse wiederholst, anstatt sie zu bearbeiten.</p>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <label className="flex items-center mt-5 mb-6">
                        <input
                            type="checkbox"
                            className="toggle-checkbox hidden"
                            checked={isDefault}
                            onChange={handleToggleChange}
                        />
                        <span
                            className={`toggle-switch w-[64px] h-[30px] flex items-center rounded-full p-1 cursor-pointer transition ${isDefault ? 'bg-[#9C68EF]' : 'bg-neutral-200'}`}
                        >
                            <span
                                className={`toggle-dot w-[24px] h-[24px] bg-white rounded-full shadow-md transition transform ${isDefault ? 'translate-x-8' : 'translate-x-0'}`}
                            ></span>
                        </span>
                        <span className="text-[14px] ml-4 font-medium leading-[21px] tracking-[0%] text-[#6F6F6F]">
                            Als Standard-Tonfall speichern
                        </span>
                    </label>
                    {analyzeError && (
                        <span className="text-red-500 text-sm">{analyzeError}</span>
                    )}
                    <div className="flex items-center">
                        <button
                            className="flex mr-5 items-center justify-center p-[12px] bg-secondary hover:bg-secondary-600 text-white font-medium rounded-[4px] text-[14px] font-bold leading-[16px] tracking-[1%]"
                            onClick={submitNewPersona}
                        >
                            <span className="mr-2">
                                <PersonaIcon />
                            </span>
                            {isEditing ? 'Änderungen speichern' : 'Tonfall speichern'}
                        </button>
                        {!isEditing && (
                            <button
                                onClick={handleAnalyze}
                                className="text-[14px] flex border p-3 rounded-[4px] border-white hover:border-[#808080] font-medium leading-[16px] tracking-[1%] text-left text-[#808080]">
                                {isAnalyzing ? (
                                    <>
                                        <LoaderIcon className="animate-spin h-4 w-4 text-[#808080] mr-2" />
                                        Analyzing...
                                    </>
                                ) : (
                                    "Analyse wiederholen"
                                )}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonaForm;
