import React, { useState, useEffect, useRef } from "react";
import { functions } from "../../config/fbConfig";
import { gemini_model } from "../../config/models";
import firebase from "../../config/fbConfig";
import { httpsCallable } from "firebase/functions";
import LayoutInSide from "../../layouts/LayoutInSide";
import { toast } from "react-toastify";
import { usePostHog } from 'posthog-js/react';
import { auth } from "../../config/fbConfig";
import { UserGuideStepEnum } from "../../consts";
import { useShareableData } from "../../providers/ShareableDataProvider";
import UpgradeModal from "../../layouts/Modal/UpgradeModal";
import PulsingMessageSkeleton from "../../components/loader/message_skeleton";
import { Loader2 } from "lucide-react";
import { Link, useNavigate } from 'react-router-dom';
import { getAllUserData } from "~/utils/userUtils";
import { Search, FileText, Brain, Send, User } from "lucide-react";
import { Card, CardContent } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { PromptLibrary } from "../../components/prompt-library/PromptLibrary";
import { EnhancedTextarea } from "../../components/chat/EnhancedTextarea";
import { ProfileIcon } from "../../components/profile/ProfileIcon";
import { Message } from "../../components/chat/Message";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { ChevronUp } from "lucide-react";
import { Wand2 } from "lucide-react";
import { PersonaIconSidebar } from "../../assets/Icons";
import { useUserData } from "../../providers/UserDataProvider";

// Define the expected shape of the result data
interface ResultData {
  error?: string;
  sessionToken?: string;
}

// Create a wrapper component that matches LucideIcon type
const PersonaIcon = React.forwardRef<SVGSVGElement>((props, ref) => (
  <div className="h-4 w-4">
    <PersonaIconSidebar />
  </div>
));

PersonaIcon.displayName = 'PersonaIcon';

const chat = httpsCallable(functions, "chat", { timeout: 300000 });

function ChatPage() {
  // Interface for the chat messages
  interface Message {
    content: string;
    type: string;  // can be 'user', 'assistant', or 'tool_call'
    persona?: string;
  }

  const [text, setText] = useState("");
  const [chatData, setChatData] = useState<Message[]>([]);
  const sessionTokenRef = useRef<string>("");
  // Boolean to check if we are waiting for an answer
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { showOnBoarding, completeOnBoardingStep} = useShareableData();
  const posthog = usePostHog();
  // Helper ref and function to scroll to the bottom of the chat
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const initialized = useRef(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [currentChatId, setCurrentChatId] = useState<string | null>(null);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);
  const [isPromptLibraryOpen, setIsPromptLibraryOpen] = useState(false);
  const [setUserData] = useState<any>(null);
  const [firstLetter, setFirstLetter] = useState<string>("");
  const { personas } = useUserData();
  const [selectedPersona, setSelectedPersona] = useState<string | null>(null);
  const navigate = useNavigate();
  const [isImproving, setIsImproving] = useState(false);

  // Functions
  const init_chat = httpsCallable(functions, "initialize_chat");

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [chatData]);

  const loadUserData = async () => {
    let retrievedUserData = await getAllUserData(auth.currentUser!.uid);
    setIsFreeTrial(retrievedUserData.userData!.freetrial || false);
  };

  useEffect(() => {
    loadUserData();
  }, []);

  // useEffect to instantiate a new chat session using the init_chat callable function
  // only called when the sessionToken is empty
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionTokenParam = urlParams.get('sessionToken');

    if (sessionTokenParam) {
      sessionTokenRef.current = sessionTokenParam;
      setCurrentChatId(sessionTokenParam);
    } else if (!sessionTokenRef.current && !initialized.current) {
      initialized.current = true;
      const startTime = performance.now();
      init_chat()
        .then((result) => {
          const newSessionToken = (result.data as ResultData).sessionToken;
          if (newSessionToken) {
            sessionTokenRef.current = newSessionToken;
            setCurrentChatId(newSessionToken);
          } else {
            console.error("Failed to retrieve session token");
          }
          posthog.capture('chat_page:new_chat', {
            thread_id: newSessionToken,
            load_time_ms: performance.now() - startTime
          });
        })
        .catch((error) => {
          console.error("Chat session failed to initialize:", error);
          // Show an error message to the user
        });
    } else {
    }
  //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const loadUserData = async () => {
      if (auth.currentUser) {
        const data = await getAllUserData(auth.currentUser.uid);
        
        const fullName = data?.userData?.fullname || 
                        `${data?.userData?.firstname || ''} ${data?.userData?.lastname || ''}`;
        setFirstLetter(fullName.charAt(0).toUpperCase());
      }
    };

    loadUserData();
  }, [setUserData]);

  useEffect(() => {
    if (isFreeTrial && chatData.filter(msg => msg.type !== 'tool_call').length >= 6) {
      setShowUpgradePrompt(true);
    }
  }, [chatData, isFreeTrial]);

  // Function to upload the user's message to the chat
  const handleMessageSubmit = async (message?: string) => {
    try {
      setButtonDisabled(true);
      setIsWaitingForResponse(true);

      while (!sessionTokenRef.current) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      // Use the provided message or fall back to the 'text' variable
      let messageToSend = message ?? text;
      
      // Append the selected persona name if one is selected
      if (selectedPersona) {
        const personaName = personas.find(p => p.personatext === selectedPersona)?.name.replace('★ ', '');
        messageToSend = `${messageToSend}\n---Nutze den Tonfall <${personaName}>`;
      }

      const data = { 
        "sessionToken": sessionTokenRef.current, 
        "message": messageToSend,
        "systemPrompt": "You are a helpful assistant."
      };

      // Reset the selected persona after sending
      setSelectedPersona(null);

      const result = await chat(data) as { data: ResultData };

      // Check if 'error' key exists in the response and if it's true
      if (result.data && result.data.hasOwnProperty('error') && result.data.error) {
        var errorText: string = "Etwas ist schief gelaufen. Bitte versuchen sie es später noch einmal.";
        if (result.data.error === "Max words exceeded") {
          errorText = "Die eingegebene Nachricht ist zu lang. Bitte versuchen Sie es mit einer kürzeren Nachricht erneut.";
        } else if (result.data.error === "Message while active run") {
          errorText = "Es können keine Nachrichten gesendet werden, während der Chat aktiv ist. Bitte warten Sie auf eine Antwort.";
        } else if (result.data.error === "Not enough words left") {
          setShowUpgradeModal(true);
          setButtonDisabled(false);
          return;
        }
        toast.error(errorText, {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setButtonDisabled(false);
        return true;
      }

      posthog.capture('chat_page:message_submit', {
        thread_id: sessionTokenRef.current
      });

      if(showOnBoarding){
        completeOnBoardingStep(UserGuideStepEnum.CHAT)
      };

      setButtonDisabled(false);
    } catch (error) {
      console.error("Error submitting message:", error);
      toast.error("Failed to send message. Please try again.");
      setButtonDisabled(false);
      setIsWaitingForResponse(false);
    }
  };

  // Continuously load the chat data from firestore
  useEffect(() => {
    if (!currentChatId) return;

    const db = firebase.firestore();
    const docRef = db.collection('users').doc(auth.currentUser!.uid).collection('chat').doc(currentChatId);


    const unsubscribe = docRef.onSnapshot((doc: any) => {
      if (doc.exists) {
        const data = doc.data();
        if (data) {
          if (chatData.length >= 2 || data.messages.length >= 2) {
            setChatData(data.messages);
          }
          if (isWaitingForResponse && data.messages.length > chatData.length) {
            const lastMessage = data.messages[data.messages.length - 1];
            if (lastMessage.type === 'assistant' && lastMessage.content.trim() !== '') {
              setIsWaitingForResponse(false);
            }
          }
        }
      }
    }, (error) => {
      console.error(`Firestore listener error for chat ${currentChatId}:`, error);
    });

    return () => {
      unsubscribe();
    };
  //eslint-disable-next-line
  }, [currentChatId, isWaitingForResponse, isFreeTrial]);

  // Add this helper function inside ChatPage but before the return statement
  const insertPrompt = (promptText: string) => {
    setText(promptText);
    // Optional: Focus the textarea after inserting
    const textarea = document.querySelector('textarea');
    if (textarea) {
      textarea.focus();
    }
  };

  const handlePromptSelect = (prompt: string) => {
    setText(prompt);
  };

  const handleImprove = async () => {
    if (!text.trim() || isImproving || buttonDisabled) return;
    
    setIsImproving(true);
    setButtonDisabled(true);

    try {
      const request = [
        { 
          text: "Du bist ein hilfreicher Schreibassistent. " +
                "Verbessere den Folgenden Prompt und behalte dabei seine Kernaussage bei. " +
                "Füge details und Beispiele hinzu. " +
                "Behalte die gleiche Sprache wie die Eingabe bei. " +
                "Falls weitere Informationen notwendig sind, setze einen Platzhaler indem du Worte mit eckigen Klammern ersetzt. " +
                "Hier ist ein beispiel eines Guten prompts: " +
                "```Du bist ein Recruiter, der Spitzenkräfte anziehen möchte.\n" +
                "Du hast dieses LinkedIn-Profil gefunden [LinkedIn-URL].\n" +
                "Du musst sie ansprechen und darfst nicht mehr als 500 Zeichen verwenden.\n" +
                "Deine Nachricht sollte beinhalten:\n" +
                "- den Namen der Firma, bei der sie derzeit arbeiten\n" +
                "- ein Lob für ihre Fachgebiete\n" +
                "- warum sie gut als [Rolle] passen würden\n" +
                "- spannende Neuigkeiten über [Unternehmens-URL]\n" +
                "Die Nachricht sollte spannend sein und Interesse wecken, mehr über die Möglichkeit zu erfahren.``` " +
                "Antworte nur mit dem Prompt. Er sollte nicht länger doppelt so lang sein wie die Eingabe." +
                "Hier ist der Prompt: "
        },
        { text: text }
      ];

      const result = await gemini_model.generateContentStream(request);
      
      // Clear the existing text
      setText("");
      
      // Stream the improved text into the input field
      for await (const chunk of result.stream) {
        if (chunk.text()) {
          setText(prev => prev + chunk.text());
        }
      }

      posthog.capture('chat_page:improve_text', {
        thread_id: sessionTokenRef.current
      });

    } catch (error) {
      console.error("Error improving text:", error);
      toast.error("Fehler beim Verbessern des Textes. Bitte versuchen Sie es erneut.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsImproving(false);
      setButtonDisabled(false);
    }
  };

  return (
    <LayoutInSide>
      <div className="col-1 w-full h-[calc(100vh-4rem)] flex flex-col border border-grey shadow bg-white rounded mx-auto">
        {/* Header section - only shown when chat is empty */}
        {chatData.length === 0 && (
          <div className="flex-none">
            <div className="p-4 md:p-8 lg:p-12 bg-white">
              <div className="max-w-4xl mx-auto">
                <h1 className="text-3xl font-semibold text-gray-900 mb-3">
                  Wilkommen im Robowriter Chat
                </h1>
                <p className="text-gray-600 mb-8 text-lg">
                  Schreibe eine Anfrage in unseren Chat und Robowriter übernimmt den Rest. Weißt Du nicht, wo Du anfangen sollst? Schaue in der Prompt-Bibliothek nach, um Dich inspirieren zu lassen.
                </p>

                <div className="grid gap-6 md:gap-8 max-w-full">
                  {/* Search Section */}
                  <Card className="p-4 sm:p-6 w-full overflow-hidden">
                    <div className="flex items-start gap-4 min-w-0">
                      <div className="bg-blue-100 p-3 rounded-lg flex-shrink-0">
                        <Search className="w-6 h-6 text-blue-600" />
                      </div>
                      <div className="flex-1 min-w-0">
                        <h3 className="font-medium text-gray-900 mb-4 text-lg break-words">Internet-Suche</h3>
                        <div className="space-y-2">
                          <button 
                            className="block w-full text-left text-base text-gray-500 hover:text-gray-900 hover:bg-gray-50 rounded p-3 transition-colors break-words whitespace-normal"
                            onClick={() => insertPrompt("Fasse die letzten Nachrichten über generative KI zusammen")}
                          >
                            "Fasse die letzten Nachrichten über generative KI zusammen"
                          </button>
                          <button 
                            className="block w-full text-left text-base text-gray-500 hover:text-gray-900 hover:bg-gray-50 rounded p-3 transition-colors break-words whitespace-normal"
                            onClick={() => insertPrompt("Fasse diese Webseite zusammen [URL]")}
                          >
                            "Fasse diese Webseite zusammen [URL]"
                          </button>
                        </div>
                      </div>
                    </div>
                  </Card>

                  {/* Long-form Content Section */}
                  <Card className="p-4 sm:p-6 w-full overflow-hidden">
                    <div className="flex items-start gap-4 min-w-0">
                      <div className="bg-purple-100 p-3 rounded-lg flex-shrink-0">
                        <FileText className="w-6 h-6 text-purple-600" />
                      </div>
                      <div className="flex-1 min-w-0">
                        <h3 className="font-medium text-gray-900 mb-4 text-lg break-words">Langformige Inhalte</h3>
                        <div className="space-y-2">
                          <button 
                            className="block w-full text-left text-base text-gray-500 hover:text-gray-900 hover:bg-gray-50 rounded p-3 transition-colors break-words whitespace-normal"
                            onClick={() => insertPrompt("Erstelle einen Blog-Beitrag über Suchmaschinenoptimierung")}
                          >
                            "Erstelle einen Blog-Beitrag über Suchmaschinenoptimierung"
                          </button>
                          <button 
                            className="block w-full text-left text-base text-gray-500 hover:text-gray-900 hover:bg-gray-50 rounded p-3 transition-colors break-words whitespace-normal"
                            onClick={() => insertPrompt("Erstelle einen Pressemitteilung über [Unternehmensname]")}
                          >
                            "Erstelle eine Pressemitteilung über [Unternehmensname]"
                          </button>
                        </div>
                      </div>
                    </div>
                  </Card>

                  {/* Brainstorming Section */}
                  <Card className="p-4 sm:p-6 w-full overflow-hidden">
                    <div className="flex items-start gap-4 min-w-0">
                      <div className="bg-green-100 p-3 rounded-lg flex-shrink-0">
                        <Brain className="w-6 h-6 text-green-600" />
                      </div>
                      <div className="flex-1 min-w-0">
                        <h3 className="font-medium text-gray-900 mb-4 text-lg break-words">Ideen Brainstorming</h3>
                        <div className="space-y-2">
                          <button 
                            className="block w-full text-left text-base text-gray-500 hover:text-gray-900 hover:bg-gray-50 rounded p-3 transition-colors break-words whitespace-normal"
                            onClick={() => insertPrompt("Erstelle 10 Instagram-Bildbeschreibungen für die Fashion Week")}
                          >
                            "Erstelle 10 Instagram-Bildbeschreibungen für die Fashion Week"
                          </button>
                          <button 
                            className="block w-full text-left text-base text-gray-500 hover:text-gray-900 hover:bg-gray-50 rounded p-3 transition-colors break-words whitespace-normal"
                            onClick={() => insertPrompt("Erstelle eine Produktbeschreibung für ein Fahrrad im Stil von Hemingway")}
                          >
                            "Erstelle eine Produktbeschreibung für ein Fahrrad im Stil von Hemingway"
                          </button>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Messages container - should flex-grow and scroll */}
        <div className="flex-1 overflow-y-auto min-h-0 scroll-smooth">
          <div className="p-4 md:p-6 space-y-4">
            {chatData.map((message, index) => {
              if (isWaitingForResponse && index === chatData.length - 1 && message.type === 'assistant') {
                return null;
              }
              if (message.content === '') {
                return null;
              }

              // Skip rendering tool_call messages that set personas
              if (message.type === 'tool_call' && message.content.startsWith('SET_PERSONA:')) {
                // Extract persona name and set it for the next assistant message
                const persona = message.content.replace('SET_PERSONA:', '').trim();
                // Find next assistant message after this index
                const nextAssistantIndex = chatData.findIndex((msg, i) => 
                  i > index && msg.type === 'assistant'
                );
                
                if (nextAssistantIndex !== -1) {
                  chatData[nextAssistantIndex].persona = persona;
                }
                return null;
              }

              // Handle other tool_call messages as before
              if (message.type === 'tool_call') {
                return (
                  <div key={index} className="ml-12 flex items-start gap-2 text-gray-500 text-sm">
                    <svg 
                      width="16" 
                      height="16" 
                      viewBox="0 0 16 16" 
                      fill="none" 
                      className="mt-1 flex-shrink-0"
                    >
                      <path 
                        d="M2 8h10M12 8l-4 -4M12 8l-4 4" 
                        stroke="currentColor" 
                        strokeWidth="2"
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="break-all">
                      {message.content.startsWith("Google-Suche nach:") ? (
                        <>
                          Google-Suche nach:{" "}
                          <a 
                            href={`https://www.google.com/search?q=${encodeURIComponent(message.content.replace("Google-Suche nach:", "").trim())}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary hover:underline"
                          >
                            {message.content.replace("Google-Suche nach:", "").trim()}
                          </a>
                        </>
                      ) : message.content.startsWith("Webseite") ? (
                        <>
                          {message.content.split(" ").map((word, i, arr) => {
                            if (word.startsWith("http")) {
                              return (
                                <a
                                  key={i}
                                  href={word}
                                  target="_blank"
                                  rel="noopener noreferrer" 
                                  className="text-primary hover:underline"
                                >
                                  {word}
                                </a>
                              );
                            }
                            return " " + word + (i < arr.length - 1 ? " " : "");
                          })}
                        </>
                      ) : (
                        message.content
                      )}
                    </span>
                  </div>
                );
              }

              return (
                <div key={index} className="relative">
                  <Message
                    content={message.content}
                    isAI={message.type === 'assistant'}
                    isBlurred={
                      isFreeTrial && 
                      message.type !== 'tool_call' &&
                      chatData
                        .filter(msg => msg.type !== 'tool_call')
                        .findIndex(msg => msg === message) >= 5
                    }
                    onCopy={(content) => {
                      navigator.clipboard.writeText(content);
                      posthog.capture('chat_page:copy_message', {
                        thread_id: sessionTokenRef.current
                      });
                    }}
                    firstLetter={firstLetter}
                    persona={message.persona}
                  />

                </div>
              );
            })}

            {/* Loading skeleton */}
            {isWaitingForResponse && (
              <div className="p-6 rounded-xl border border-gray-100 bg-gray-50">
                <div className="flex gap-4">
                  <ProfileIcon 
                    src="/assets/images/logo-0.svg"
                    className="w-8 h-8 flex-shrink-0 p-0 object-contain"
                  />
                  <div className="flex-grow">
                    <PulsingMessageSkeleton />
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Add bottom padding to ensure last message is fully visible */}
          <div className="h-4" />
        </div>

        {/* Input section - sticky at bottom */}
        <div className="flex-none sticky bottom-0 p-4 bg-white">
          <Card className="border border-gray-200 rounded-lg shadow-none">
            <CardContent className="p-0">
              <EnhancedTextarea
                value={text}
                onChange={setText}
                placeholder="Anfrage eingeben..."
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    if (text.trim() !== "" && !buttonDisabled && !showUpgradePrompt) {
                      setChatData([...chatData, { content: text, type: 'user' }]);
                      handleMessageSubmit();
                      setText("");
                    }
                  }
                }}
                maxRows={20}
                disabled={showUpgradePrompt}
              />
            </CardContent>
            <div className="flex items-center gap-2 px-4 py-2 bg-gray-50 border-t rounded-b-lg">
              <div className="flex items-center gap-4">
                <button 
                  onClick={() => setIsPromptLibraryOpen(true)}
                  className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
                >
                  <FileText className="h-4 w-4" />
                  <span className="text-sm">Prompt-Bibliothek</span>
                </button>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <button 
                      className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
                    >
                      <PersonaIcon/>
                      <span className="text-sm">
                        {selectedPersona ? 
                          personas.find(p => p.personatext === selectedPersona)?.name : 
                          "Tonfall"}
                      </span>
                      <ChevronUp className="h-3 w-3" />
                    </button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent 
                    align="start" 
                    side="top" 
                    className="w-56 bg-white p-2 shadow-lg"
                  >
                    <DropdownMenuItem
                      onClick={() => setSelectedPersona(null)}
                      className="py-2 px-3 cursor-pointer rounded-md"
                    >
                      Kein Tonfall
                    </DropdownMenuItem>
                    
                    {personas.map((persona) => (
                      <DropdownMenuItem
                        key={persona.id}
                        onClick={() => {
                          setSelectedPersona(persona.personatext);
                          posthog.capture('chat_page:persona_selected', {
                            persona_name: persona.name
                          });
                        }}
                        className="py-2 px-3 cursor-pointer rounded-md"
                      >
                        {persona.isPersonal ? `★ ${persona.name}` : persona.name}
                      </DropdownMenuItem>
                    ))}
                    <DropdownMenuItem
                      onClick={() => navigate('/tonfall')}
                      className="text-primary py-2 px-3 cursor-pointer rounded-md mt-1 font-medium"
                    >
                      + Tonfall erstellen
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <div className="ml-auto flex items-center gap-4">
                <Button 
                  size="sm" 
                  variant="ghost" 
                  className="text-gray-600 hover:text-gray-900 hover:bg-transparent p-0 flex items-center gap-2"
                  disabled={buttonDisabled || showUpgradePrompt || !text.trim()}
                  onClick={handleImprove}
                >
                  {isImproving ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                      <span className="hidden sm:inline text-sm">Verbessern</span>
                    </>
                  ) : (
                    <>
                      <Wand2 className="h-4 w-4" />
                      <span className="hidden sm:inline text-sm">Verbessern</span>
                    </>
                  )}
                </Button>
                <Button 
                  size="sm" 
                  variant="ghost" 
                  className="text-gray-600 hover:text-gray-900 hover:bg-transparent p-0"
                  disabled={buttonDisabled || showUpgradePrompt || !text.trim()}
                  onClick={() => {
                    if (text.trim() !== "" && !showUpgradePrompt) {
                      setChatData([...chatData, { content: text, type: 'user' }]);
                      handleMessageSubmit();
                      setText("");
                    }
                  }}
                >
                  {buttonDisabled ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : (
                    <Send className="h-4 w-4" />
                  )}
                </Button>
              </div>
            </div>
          </Card>
          {/* Upgrade prompt positioning adjusted */}
          {showUpgradePrompt && (
            <div className="absolute bottom-full mb-4 left-1/2 transform -translate-x-1/2 bg-white border border-gray-300 rounded-lg shadow-lg p-4 max-w-lg w-[calc(100%-2rem)]">
              <div className="flex justify-between items-center mb-3">
                <h3 className="text-lg font-semibold">Schalte den vollständigen Verlauf frei</h3>
                <Link 
                  to="/upgrade" 
                  className="bg-secondary text-white px-4 py-2 rounded hover:bg-secondary-600 transition-colors duration-200"
                >
                  Upgrade
                </Link>
              </div>
              <p className="text-gray-600">
                Wenn Dein Chatverlauf mehr als drei Anfragen enthält, werden neue Nachrichten ausgeblendet. 
                Nutze die Premium-Version, um unbegrenzten Zugang zu erhalten.
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      <UpgradeModal isOpen={showUpgradeModal} onRequestClose={() => {setShowUpgradeModal(false)}} />  
      <PromptLibrary 
        isOpen={isPromptLibraryOpen}
        onClose={() => setIsPromptLibraryOpen(false)}
        onSelectPrompt={handlePromptSelect}
      />
    </LayoutInSide>
  );
}

export default ChatPage;
