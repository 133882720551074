import { ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { doc, collection, onSnapshot, firestore, auth, getDoc, getDocs, updateDoc, setDoc } from '../config/fbConfig';
import { UserData } from '../utils/userUtils';
import { getStripeProducts, getUserTier } from '../utils/userUtils';
import { Organization, OrganizationMember, Template, subscribeToUserOrganizations, subscribeToOrganizationPersonas, Persona, subscribeToOrganizationTemplates } from '../utils/organizationUtils';

interface UserDataContextType {
  userData: UserData | null;
  isLoading: boolean;
  writeData: any | null;
  templates: Template[];
  recentChats: any[];
  sidebarState: boolean;
  userTier: string | null;
  personas: Persona[];
  organizations: Organization[];
  setSidebarState: (state: boolean) => void;
  defaultPersonaId: string | null;
  setDefaultPersonaId: (id: string) => Promise<void>;
}

const UserDataContext = createContext<UserDataContextType>({
  userData: null,
  isLoading: true,
  writeData: null,
  templates: [],
  recentChats: [],
  sidebarState: false,
  userTier: null,
  personas: [],
  organizations: [],
  setSidebarState: () => {},
  defaultPersonaId: null,
  setDefaultPersonaId: async () => {}
});

export const UserDataProvider = ({ children }: { children: ReactNode }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [writeData, setWriteData] = useState<any | null>(null);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [recentChats, setRecentChats] = useState<any[]>([]);
  const [sidebarState, setSidebarState] = useState(false);
  const [userTier, setUserTier] = useState<string | null>(null);
  const [personas, setPersonas] = useState<Persona[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [defaultPersonaId, setDefaultPersonaId] = useState<string | null>(null);

  useEffect(() => {
    let unsubscribes: (() => void)[] = [];
    const defaultTemplates: Template[] = require('../data/templates.json');

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (!user) {
        setTemplates(defaultTemplates);
        setIsLoading(false);
        setOrganizations([]);
        setPersonas([]);
        return;
      }

      const userDocRef = doc(collection(firestore, "users"), user.uid);
      const writeDataRef = doc(collection(firestore, "users", user.uid, "writedata"), "appdata");
      const templatesRef = collection(firestore, "users", user.uid, "templates");
      const personasRef = collection(firestore, "users", user.uid, "personas");

      // User data listener
      unsubscribes.push(onSnapshot(userDocRef, async (userDocSnap: any) => {
        if (userDocSnap.exists()) {
          const userDocData = userDocSnap.data();
          setUserData(prevData => ({
            userData: userDocData,
            productData: prevData?.productData || null
          }));

          // Set up organization subscription
          if (userDocData.organizations) {
            try {
              const unsubOrgs = subscribeToUserOrganizations(user.uid, (updatedOrgs) => {
                setOrganizations(updatedOrgs);
                
                // Set up templates subscription when organizations change
                const unsubTemplates = subscribeToOrganizationTemplates(updatedOrgs, (orgTemplates) => {
                  setTemplates(prevTemplates => {
                    const userTemplates = prevTemplates.filter(t => !t.organizationId);
                    return [
                      ...userTemplates,
                      ...orgTemplates,
                      ...defaultTemplates.filter(dt => 
                        !userTemplates.some(ut => ut.id === dt.id) &&
                        !orgTemplates.some(ot => ot.id === dt.id)
                      )
                    ];
                  });
                });
                unsubscribes.push(unsubTemplates);
              });
              unsubscribes.push(unsubOrgs);
            } catch (error) {
              console.error("Error subscribing to organizations:", error);
              setOrganizations([]);
            }
          } else {
            setOrganizations([]);
          }
        }
      }));

      // Templates listener for user templates
      unsubscribes.push(onSnapshot(templatesRef, (templatesSnap) => {
        try {
          const userTemplates: Template[] = templatesSnap.docs.map(doc => ({
            ...(doc.data().template_data || {
              id: doc.id,
              title: doc.data().title,
              type: doc.data().type,
              favorite: doc.data().favorite
            }),
            id: doc.id
          }));

          setTemplates(prevTemplates => {
            const orgTemplates = prevTemplates.filter(t => t.organizationId);
            return [
              ...userTemplates,
              ...orgTemplates,
              ...defaultTemplates.filter(dt => 
                !userTemplates.some(ut => ut.id === dt.id) &&
                !orgTemplates.some(ot => ot.id === dt.id)
              )
            ];
          });
        } catch (error) {
          console.error("Error processing templates:", error);
          setTemplates([...defaultTemplates]);
        }
      }));

      // Write data listener
      unsubscribes.push(onSnapshot(writeDataRef, (writeDataSnap: any) => {
        if (writeDataSnap.exists()) {
          const data = writeDataSnap.data();
          setWriteData(data);
          setDefaultPersonaId(data.defaultPersonaId || null);
        }
      }));

      // Recent chats listener
      const chatDocRef = doc(collection(firestore, "users", user.uid, "chat"), "recent");
      unsubscribes.push(
        onSnapshot(chatDocRef, (doc) => {
          if (doc.exists()) {
            const data = doc.data()?.chats;
            if (data) {
              setRecentChats(data);
            }
          }
        })
      );

      // Get initial sidebar state
      getDoc(doc(collection(firestore, "users", user.uid, "writedata"), "appdata"))
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            setSidebarState(docSnapshot.data()?.sidebarState || false);
          }
        });

      // Get user tier
      const fetchUserTier = async () => {
        if (!auth.currentUser) return
        const userTier = await getUserTier(auth.currentUser.uid);
        const data = await getStripeProducts();

        let subscriptionName = userTier.firebaseRole;
        for (let key in data) {
          if (JSON.stringify(data[key]) === JSON.stringify(userTier)) {
            subscriptionName = key;
            break;
          }
        }
        setUserTier(subscriptionName);
      };
      fetchUserTier();

      // Personal personas listener
      unsubscribes.push(onSnapshot(personasRef, (personasSnap) => {
        try {
          const userPersonas: Persona[] = [];
          personasSnap.forEach((doc) => {
            const personaData = doc.data();
            userPersonas.push({
              id: doc.id,
              name: personaData.name,
              personatext: personaData.personatext,
              type: personaData.type || 'Personal',
              created_at: personaData.created_at?.toDate() || new Date(),
              originalOwnerId: user.uid,
              isPersonal: true
            });
          });

          // Store personal personas separately instead of updating state directly
          setPersonas(prevPersonas => {
            const orgPersonas = prevPersonas.filter(p => p.organizationId);
            return [...userPersonas, ...orgPersonas];
          });
        } catch (error) {
          console.error("Error processing personas:", error);
        }
      }));

      // Initialize loading state
      Promise.all([
        getDoc(userDocRef),
        getDoc(writeDataRef)
      ]).finally(() => setIsLoading(false));
    });

    return () => {
      unsubscribeAuth();
      unsubscribes.forEach(unsubscribe => unsubscribe());
    };
  }, []);

  // Add new useEffect to handle organization personas subscription
  useEffect(() => {
    if (!auth.currentUser || organizations.length === 0) return;

    const unsubscribe = subscribeToOrganizationPersonas(organizations, (orgPersonas) => {
      setPersonas(prevPersonas => {
        const personalPersonas = prevPersonas.filter(p => !p.organizationId);
        return [...personalPersonas, ...orgPersonas];
      });
    });

    return () => unsubscribe();
  }, [organizations]); // Only re-run when organizations change

  const updateSidebarState = async (newState: boolean) => {
    if (!auth.currentUser?.uid) return;
    
    const writeDataDocRef = doc(collection(firestore, "users", auth.currentUser.uid, "writedata"), "appdata");
    const docSnapshot = await getDoc(writeDataDocRef);
    
    if (docSnapshot.exists()) {
      await updateDoc(writeDataDocRef, { sidebarState: newState });
    } else {
      await setDoc(writeDataDocRef, { sidebarState: newState });
    }
    setSidebarState(newState);
  };

  const updateDefaultPersonaId = async (id: string) => {
    if (!auth.currentUser) return;
    
    const writeDataDocRef = doc(collection(firestore, "users", auth.currentUser.uid, "writedata"), "appdata");
    await updateDoc(writeDataDocRef, { defaultPersonaId: id });
    setDefaultPersonaId(id);
  };

  return (
    <UserDataContext.Provider value={{ 
      userData, 
      writeData, 
      templates, 
      isLoading,
      recentChats,
      sidebarState,
      userTier,
      personas,
      organizations,
      setSidebarState: updateSidebarState,
      defaultPersonaId,
      setDefaultPersonaId: updateDefaultPersonaId
    }}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => useContext(UserDataContext); 