import { useState } from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "~/components/ui/card";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { toast } from "react-toastify";
import { functions } from "../../config/fbConfig";
import { httpsCallable } from "firebase/functions";
import MiniSpinningLoader from "../../components/loader/mini_spinning_loader";
import { useUserData } from "../../providers/UserDataProvider";
import { Separator } from "~/components/ui/separator";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "~/components/ui/table";
import { Badge } from "~/components/ui/badge";
import { Organization, OrganizationMember } from "../../utils/organizationUtils";
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger 
} from "~/components/ui/dropdown-menu";
import { MoreHorizontal } from "lucide-react";
import OrganizationManagerSkeleton from "./OrganizationManagerSkeleton";
import OrganizationPaywall from "./OrganizationPaywall";

// Only keep these type definitions as they're not in organizationUtils

// Add this type definition
type OrgRole = 'admin' | 'member' | 'pending';

// Add this helper function before the component
const sortMembers = (members: OrganizationMember[]) => {
  const roleOrder: Record<OrgRole, number> = {
    admin: 0,
    member: 1,
    pending: 2
  };
  
  return members.sort((a, b) => {
    // First sort by role
    const roleComparison = roleOrder[a.role as OrgRole] - roleOrder[b.role as OrgRole];
    if (roleComparison !== 0) return roleComparison;
    
    // Then sort by name (fallback to email if name is not available)
    const nameA = a.fullname?.toLowerCase() || a.email.toLowerCase();
    const nameB = b.fullname?.toLowerCase() || b.email.toLowerCase();
    return nameA.localeCompare(nameB);
  });
};

export default function OrganizationManager() {
  const { organizations, userData } = useUserData();
  const [orgName, setOrgName] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [removingMember, setRemovingMember] = useState<string | null>(null);

  // Get organization data from user data
  const userOrgs = userData?.userData?.organizations || {};
  const hasOrg = Object.keys(userOrgs).length > 0;
  const orgId = hasOrg ? Object.keys(userOrgs)[0] : null;
  const currentOrg = orgId ? userOrgs[orgId] : null;
  const isAdmin = hasOrg && currentOrg?.role === 'admin';
  const orgCollData = organizations?.[0] as Organization;
  const hasTeamAccess = userData?.userData?.unlimited === true;

  // Check if user has a robowriter.de email
  const isRobowriterEmail = userData?.userData?.email?.endsWith('@robowriter.de') ?? false;

  // Show paywall for non-robowriter.de emails
  if (!isRobowriterEmail) {
    return <OrganizationPaywall />;
  }

  const handleCreateSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!orgName.trim()) {
      toast.error("Bitte geben Sie einen Organisationsnamen ein.");
      return;
    }

    setIsCreating(true);
    setIsTransitioning(true);
    try {
      const createOrgFunction = httpsCallable(functions, 'create_organization');
      await createOrgFunction({ name: orgName.trim() });
      toast.success("Organisation erfolgreich erstellt!");
      setOrgName("");
      // Wait a moment for the database to update and the UserDataProvider to refresh
      await new Promise(resolve => setTimeout(resolve, 1500));
    } catch (error) {
      console.error("Error creating organization:", error);
      toast.error("Fehler beim Erstellen der Organisation");
      setIsTransitioning(false);
    } finally {
      setIsCreating(false);
      window.location.reload();
    }
  };

  const handleInviteSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!inviteEmail.trim()) {
      toast.error("Bitte geben Sie eine E-Mail-Adresse ein.");
      return;
    }

    setIsInviting(true);
    try {
      const inviteFunction = httpsCallable(functions, 'invite_organization_member');
      const result = await inviteFunction({ email: inviteEmail.trim() });
      const response = result.data as { success: boolean; message: string };
      
      if (response.success) {
        toast.success("Einladung erfolgreich versendet!");
        setInviteEmail("");
      } else {
        toast.error(response.message || "Fehler beim Versenden der Einladung");
      }
    } catch (error) {
      console.error("Error inviting member:", error);
      toast.error("Fehler beim Versenden der Einladung");
    } finally {
      setIsInviting(false);
    }
  };

  const handleDeleteMember = async (member: OrganizationMember) => {
    setRemovingMember(member.email);
    try {
      const removeFunction = httpsCallable(functions, 'remove_organization_member');
      const result = await removeFunction({ email: member.email });
      const response = result.data as { success: boolean; message: string };
      
      if (response.success) {
        toast.success("Mitglied erfolgreich entfernt");
      } else {
        toast.error(response.message || "Fehler beim Entfernen des Mitglieds");
      }
    } catch (error) {
      console.error("Error removing member:", error);
      toast.error("Fehler beim Entfernen des Mitglieds");
    } finally {
      setRemovingMember(null);
    }
  };

  const handlePromoteToAdmin = async (member: OrganizationMember) => {
    try {
      const promoteFunction = httpsCallable(functions, 'promote_organization_member');
      const result = await promoteFunction({ email: member.email });
      const response = result.data as { success: boolean; message: string };
      
      if (response.success) {
        toast.success("Mitglied erfolgreich zum Administrator befördert");
      } else {
        toast.error(response.message || "Fehler beim Befördern des Mitglieds");
      }
    } catch (error) {
      console.error("Error promoting member:", error);
      toast.error("Fehler beim Befördern des Mitglieds");
    }
  };

  const handleDemoteAdmin = async (member: OrganizationMember) => {
    try {
      const demoteFunction = httpsCallable(functions, 'demote_organization_member');
      const result = await demoteFunction({ email: member.email });
      const response = result.data as { success: boolean; message: string };
      
      if (response.success) {
        toast.success("Administrator erfolgreich zum Mitglied herabgestuft");
      } else {
        toast.error(response.message || "Fehler beim Herabstufen des Administrators");
      }
    } catch (error) {
      console.error("Error demoting admin:", error);
      toast.error("Fehler beim Herabstufen des Administrators");
    }
  };

  if (isTransitioning) {
    return <OrganizationManagerSkeleton />;
  }

  if (hasOrg && currentOrg) {
    // Replace the loading check with skeleton
    if (!orgCollData?.name || !orgCollData?.members || Object.keys(orgCollData.members).length === 0) {
      return <OrganizationManagerSkeleton />;
    }

    return (
      <Card>
        <CardHeader>
          <CardTitle data-testid="org-manage-title">Organisation verwalten</CardTitle>
          <CardDescription data-testid="org-manage-description">
            Verwalten Sie Ihre Organisation und laden Sie neue Mitglieder ein
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <div>
              <Label>Organisationsname</Label>
              <p className="text-lg font-medium mt-1">
                {orgCollData?.name || "Robowriter"}
              </p>
            </div>
            <div>
              <Label>Deine Rolle</Label>
              <p className="text-sm text-muted-foreground mt-1">
                {(() => {
                  switch (currentOrg.role) {
                    case 'admin':
                      return 'Administrator';
                    case 'member':
                      return 'Mitglied';
                    case 'pending':
                      return 'Ausstehend';
                    default:
                      return 'Unbekannt';
                  }
                })()}
              </p>
            </div>

            <Separator />
            
            <div className="space-y-3">
              <Label>Mitglieder</Label>
              <div className="border rounded-lg">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Name</TableHead>
                      {isAdmin && <TableHead className="hidden md:table-cell">Email</TableHead>}
                      <TableHead>Rolle</TableHead>
                      {isAdmin && <TableHead className="w-[50px]"></TableHead>}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {orgCollData.members && sortMembers(Object.values(orgCollData.members)).map((member: OrganizationMember) => (
                      <TableRow key={member.uid}>
                        <TableCell className="py-4">
                          <div className="space-y-1">
                            <div className="font-medium">
                              {member.fullname || 'N/A'}
                            </div>
                            {isAdmin && (
                              <div className="text-sm text-muted-foreground md:hidden">
                                {member.email}
                              </div>
                            )}
                          </div>
                        </TableCell>
                        {isAdmin && (
                          <TableCell className="hidden md:table-cell">
                            {member.email}
                          </TableCell>
                        )}
                        <TableCell>
                          <Badge 
                            variant={member.role === 'admin' ? 'destructive' : 'outline'}
                            className={member.role === 'admin' ? '' : 'bg-secondary'}
                            data-testid={`member-role-${member.email}`}
                          >
                            {(() => {
                              switch (member.role) {
                                case 'admin':
                                  return 'Administrator';
                                case 'member':
                                  return 'Mitglied';
                                case 'pending':
                                  return 'Ausstehend';
                                default:
                                  return 'Unbekannt';
                              }
                            })()}
                          </Badge>
                        </TableCell>
                        {isAdmin && (
                          <TableCell>
                            <div className="relative flex justify-end">
                              <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                  <Button 
                                    variant="ghost" 
                                    className="h-8 w-8 p-0"
                                    data-testid={`member-actions-${member.email}`}
                                  >
                                    <MoreHorizontal className="h-4 w-4" />
                                  </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align="end" className="bg-white">
                                  {member.role === 'member' && isAdmin && (
                                    <DropdownMenuItem
                                      onClick={() => handlePromoteToAdmin(member)}
                                      data-testid={`promote-member-${member.email}`}
                                    >
                                      Zum Administrator befördern
                                    </DropdownMenuItem>
                                  )}
                                  {member.role === 'admin' && isAdmin && member.email !== userData?.userData.email && (
                                    <DropdownMenuItem
                                      onClick={() => handleDemoteAdmin(member)}
                                      data-testid={`demote-admin-${member.email}`}
                                    >
                                      Zum Mitglied herabstufen
                                    </DropdownMenuItem>
                                  )}
                                  <DropdownMenuItem
                                    onClick={() => handleDeleteMember(member)}
                                    className="text-destructive"
                                    data-testid={`delete-member-${member.email}`}
                                  >
                                    Mitglied entfernen
                                  </DropdownMenuItem>
                                </DropdownMenuContent>
                              </DropdownMenu>
                              {removingMember === member.email && (
                                <div className="absolute bottom-2 right-2">
                                  <div className="h-4 w-4">
                                    <MiniSpinningLoader />
                                  </div>
                                </div>
                              )}
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>

            {isAdmin && (
              <>
                <Separator />
                <form onSubmit={handleInviteSubmit} className="space-y-4">
                  <div className="space-y-2">
                    <Label htmlFor="inviteEmail">Neues Mitglied einladen</Label>
                    <Input
                      id="inviteEmail"
                      type="email"
                      placeholder="E-Mail-Adresse eingeben"
                      value={inviteEmail}
                      onChange={(e) => setInviteEmail(e.target.value)}
                      disabled={isInviting}
                      data-testid="invite-member-input"
                    />
                  </div>
                  <Button 
                    type="submit" 
                    disabled={isInviting || !inviteEmail.trim()}
                    className="w-full"
                  >
                    {isInviting ? <MiniSpinningLoader /> : "Einladung senden"}
                  </Button>
                </form>
              </>
            )}
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Organisation erstellen</CardTitle>
        <CardDescription>
          Erstellen Sie eine neue Organisation, um mit anderen Mitgliedern zusammenzuarbeiten
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleCreateSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="orgName">Organisationsname</Label>
            <Input
              id="orgName"
              placeholder="Geben Sie einen Organisationsnamen ein"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              disabled={isCreating}
              data-testid="org-name-input"
            />
          </div>
          <Button 
            type="submit" 
            disabled={isCreating || !orgName.trim()}
            className="w-full"
          >
            {isCreating ? <MiniSpinningLoader /> : "Organisation erstellen"}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}
