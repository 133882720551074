import { Lightbulb, ChevronDown } from "lucide-react"
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card"
import { Button } from "../ui/button"
import { useState } from "react"
import { cn } from "~/lib/utils"
import { Loader2 } from "lucide-react"
import { Skeleton } from "../ui/skeleton"

interface AdditionalField {
  title: string;
  description: string;
  type: "input" | "textarea";
}

interface AdditionalInformationCardProps {
  onAddField: (field: { title: string; description: string; type: "input" | "textarea" }) => void;
  onAddContext: () => void;
  isLoading: boolean;
  suggestedFields: { title: string; description: string; type: "input" | "textarea" }[];
  isExpanded: boolean;
  onToggle: () => void;
  onFieldAdded?: (fieldIndex: number) => void;
  existingFields: Array<{ label: string; type: string; description: string; }>;
}

export const AdditionalInformationCard = ({
  onAddField,
  onAddContext,
  isLoading,
  suggestedFields,
  isExpanded,
  onToggle,
  onFieldAdded,
  existingFields
}: AdditionalInformationCardProps) => {
  // Filter out suggestions that already exist
  const availableSuggestions = suggestedFields.filter(suggestion => 
    !existingFields.some(existing => 
      existing.label.toLowerCase() === suggestion.title.toLowerCase()
    )
  );

  // Check if context already exists
  const hasContext = existingFields.some(field => 
    field.label.toLowerCase() === "kontext"
  );

  const handleFieldSelection = (field: any, index: number) => (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onAddField(field);
    onToggle();
  };

  const handleContextSelection = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onAddContext();
    onToggle();
  };

  return (
    <Card className={cn(
      "border-gray-200 shadow-none mb-4 bg-white transition-all duration-200",
      isExpanded ? "ring-2 ring-purple-500 ring-opacity-50" : "hover:border-purple-200 hover:bg-gray-50"
    )}
    data-testid="additional-info-card"
    >
      <CardHeader 
        className="cursor-pointer"
        onClick={onToggle}
        data-testid="additional-info-header"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Lightbulb className="h-5 w-5 text-purple-600 flex-shrink-0" />
            <CardTitle className="text-base font-medium ml-2 pl-2">Zusätzliche Informationen anfügen</CardTitle>
          </div>
          <ChevronDown 
            className={cn(
              "h-5 w-5 text-gray-500 transition-transform duration-200 flex-shrink-0",
              isExpanded ? "transform rotate-180" : ""
            )} 
          />
        </div>
      </CardHeader>
      
      {isExpanded && (
        <CardContent className="space-y-4">
          {/* Loading state for suggestions */}
          {isLoading ? (
            <>
              <Skeleton className="w-full h-[84px]" />
              <Skeleton className="w-full h-[84px]" />
            </>
          ) : (
            availableSuggestions.length > 0 && (
              <>
                {availableSuggestions.map((field, index) => (
                  <Button
                    key={index}
                    variant="outline"
                    onClick={handleFieldSelection(field, index)}
                    className="w-full justify-start text-left h-auto py-3 px-4 hover:bg-gray-50 transition-colors duration-200"
                    data-testid={`suggested-field-${index}`}
                  >
                    <div className="w-full">
                      <div className="font-medium break-words">{field.title}</div>
                      <div className="text-sm text-gray-500 mt-1 break-words whitespace-normal">{field.description}</div>
                    </div>
                  </Button>
                ))}
              </>
            )
          )}
          
          {/* Show divider if we have suggestions (or are loading) and context isn't added */}
          {(isLoading || availableSuggestions.length > 0) && !hasContext && (
            <div className="h-px w-full bg-gray-200 my-4" />
          )}
          
          {/* Always show context button if not already added */}
          {!hasContext && (
            <Button
              variant="outline"
              onClick={handleContextSelection}
              className="w-full justify-start text-left h-auto py-3 px-4 hover:bg-gray-50 transition-colors duration-200"
              data-testid="add-context-button"
            >
              <div className="w-full">
                <div className="font-medium break-words">Kontext</div>
                <div className="text-sm text-gray-500 mt-1 break-words whitespace-normal">
                  Füge weiteren Kontext an die Vorlage an
                </div>
              </div>
            </Button>
          )}
        </CardContent>
      )}
    </Card>
  );
}; 