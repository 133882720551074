import { useState, useEffect, useCallback } from "react";
import LayoutInSide from "../../layouts/LayoutInSide";
import firebase, { collection, doc, firestore, getDoc, onSnapshot, auth } from "../../config/fbConfig";
import { functions } from "../../config/fbConfig";
import { useManageStripeSubscription } from '../../utils/ManageStripeSubscription';
import { toast } from "react-toastify";
import {
  getAuth,
  sendPasswordResetEmail,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import SpinningLoader from "../../components/loader/spinning_loader";
import MiniSpinningLoader from "../../components/loader/mini_spinning_loader";
import { UserData, getAllUserData } from "../../utils/userUtils";
import { Crisp } from "crisp-sdk-web";
import { useShareableData } from "../../providers/ShareableDataProvider";
import { UserGuideStepEnum } from "../../consts";
import ReactSwitch from 'react-switch';

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "~/components/ui/card";
import { Button } from "~/components/ui/button";
import { Label } from "~/components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/components/ui/tabs";
import { Separator } from "~/components/ui/separator";
import { Building2, User, Settings2 } from "lucide-react";
import OrganizationManager from "~/components/settings/OrganizationManager";

export default function SettingsPage() {
  // State Variables
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<UserData>({ "userData": "test", "productData": "test" });
  const [newsletter_loading, setNewsletterLoading] = useState(false);
  const [upgradeButtonLoading, setUpgradeButtonLoading] = useState(false);
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    password: "",
    freetrial: false,
    newsletter: false,
    upgradeButton: true
  });
  const { showOnBoarding, completeOnBoardingStep } = useShareableData();
  const subscription = !formData.freetrial;
  const [orgName, setOrgName] = useState("");
  const [creatingOrg, setCreatingOrg] = useState(false);

  // Add this new state for the default tab
  const [defaultTab, setDefaultTab] = useState(() => {
    // Check URL hash on initial load
    return window.location.hash === '#organization' ? 'organization' : 'account';
  });

  // Add this new function to handle tab changes
  const handleTabChange = (value: string) => {
    // Update URL hash when tab changes
    window.location.hash = value;
  };

  // Providers
  const manageStripeSubscription = useManageStripeSubscription();

  // Functions & Event Handlers

  const openIubendaPreferences = async (event: React.MouseEvent) => {
    // Prevent the default action
    event.preventDefault();

    // Get the _iub element from the DOM, call the 'openPreferences' function on it
    // Ref: https://www.iubenda.com/en/help/1205-how-to-configure-your-cookie-solution-advanced-guide#api
    const iub = (window as any)._iub || [];
    iub.cs.api.openPreferences();
  }

  const handlePwReset = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const auth = getAuth();
    sendPasswordResetEmail(auth, formData?.email)
      .then(() => {
        toast.success('Passwort-Reset E-Mail wurde verschickt.', {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
  };

  const getUserDetails = async () => {
    setLoading(true);
    const retrievedUserData = await getAllUserData(auth.currentUser!.uid);
    setUserData(retrievedUserData);
    // Some fallback logic for older sign-ups where we dont have a fullname field
    const fullName = retrievedUserData.userData?.fullname !== undefined ? retrievedUserData.userData?.fullname : `${retrievedUserData.userData?.firstname} ${retrievedUserData.userData?.lastname}`;
    setFormData({
      full_name: fullName,
      email: retrievedUserData.userData?.email,
      password: "",
      freetrial: retrievedUserData.userData?.freetrial || retrievedUserData.userData?.freetrialused,
      newsletter: retrievedUserData.userData?.newsletter?.subscribed === undefined ? false : retrievedUserData.userData?.newsletter?.subscribed,
      upgradeButton: retrievedUserData.userData?.visuals?.UpgradeButton === undefined ? true : retrievedUserData.userData?.visuals?.UpgradeButton
    });
    setLoading(false);
  };

  const subscribeUserData = useCallback(async () => {
    if (auth.currentUser) {
      const userDocRef = doc(collection(firestore, "users"), auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        onSnapshot(userDocRef, (userDocSnap: any) => {
          if (userDocSnap.exists()) {
            const userDocData = userDocSnap.data();
            setUserData({ "userData": userDocData, "productData": userData.userData.productData })
            setFormData({ ...formData, upgradeButton: userDocData?.visuals?.UpgradeButton === undefined ? true : userDocData?.visuals?.UpgradeButton });
          } else {
            // Handle the case when the document does not exist
          }
        });
      } else {
        // Handle the case when the document does not exist
      }
    } else {
      // Handle the case when auth.uid is falsy (user is not authenticated)
    }
    // Disable the eslint warning here, since we only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser]);

  const handleUpgradeButtonSwitch = async (checked: boolean) => {
    // set the newsletter_loading flag to true
    setUpgradeButtonLoading(true);

    const updateUpgradeButton = httpsCallable(functions, "update_user_settings");

    // declare the data variable with a default value
    let data = { change_data: "Upgrade Button" };

    updateUpgradeButton(data).then((result) => {
      const data = result.data as {
        success: boolean;
      };
      if (data.success) {
        setFormData({ ...formData, upgradeButton: checked });
        // set the newsletter_loading flag to false
        setUpgradeButtonLoading(false);
      }
    });
  }

  // UseEffect to handle the Newsletter Switch
  // Should run every time the formData.newsletter value changes
  const handleNewsletterSwitch = async (checked: boolean) => {
    // set the newsletter_loading flag to true
    setNewsletterLoading(true);

    const updateUserDataFunction = httpsCallable(functions, "update_user_data_py");
  
    // declare the data variable with a default value
    let data = { newsletter_subscribed: checked };
    // declare a flag to check if the newsletter is subscribed to the first time
    let first_subscription_flag = false;

    // if the newsletter is subscribed to the first time, also set the first_newsletter_subscription flag to true
    if (checked && userData.userData?.newsletter?.first_subscription !== true) {
      data = { newsletter_subscribed: checked, first_newsletter_subscription: true } as { newsletter_subscribed: boolean; first_newsletter_subscription: boolean };
      first_subscription_flag = true;
    }

    updateUserDataFunction(data).then((result) => {
      const data = result.data as {
        success: boolean;
      };
      if (data.success) {
        let text = "Einstellungen wurden erfolgreich aktualisiert.";
        // If the user subscribed for the first time (and is not in the unlimited tier), inform him about the 3 bonus creations
        if (first_subscription_flag && userData.userData?.unlimited !== true) {
          text = "Einstellungen wurden erfolgreich aktualisiert. Du hast 3 Bonus-Texte erhalten. 🔥";
          // This gets a little unreadable, what this does is this updates the userData.newsletter map to set the first_subscription flag to true
          setUserData({
            ...userData,
            userData: {
              ...userData.userData,
              newsletter: {
                ...userData.userData.newsletter,
                first_subscription: true
              }
            }
          });
        }
        toast.success(text, {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setFormData({ ...formData, newsletter: checked });
        // set the newsletter_loading flag to false
        setNewsletterLoading(false);
      } else {
        toast.error("Einstellungen konnten nicht aktualisiert werden. Bitte versuchen sie es später noch einmal.", {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setFormData({ ...formData, newsletter: checked });
        // set the newsletter_loading flag to false
        setNewsletterLoading(false);
      }
    });

    if (showOnBoarding && checked) {
      completeOnBoardingStep(UserGuideStepEnum.NEWSLETTER)
    }
  }

  const deleteaccount = async (priceId: any) => {
    const user = firebase.auth().currentUser;
    const email = prompt("Bitte geben Sie ihre Email Adresse ein um ihr Konto zu löschen:");
    if (email && user?.email === email) {
      setLoading(true);
      const functionRef = httpsCallable(functions, "delete_user_and_cancel_subscriptions_py");
      await functionRef();

      // Reset Crisp Session
      Crisp.setTokenId(); // 1. Clear the token value
      Crisp.session.reset(); // 2. Unbind the current session

      await firebase.auth().signOut();
      setLoading(false);
      toast.success("Account wurde erfolgreich gelöscht.", {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error("Account Löschen hat nicht geklappt, bitte geben Sie ihre E-Mail Adresse ein.", {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  /* UseEffect Hooks */

  useEffect(() => {
    if (formData.email === "") {
      getUserDetails();
    }
  });

  useEffect(() => {
    subscribeUserData();
  }, [subscribeUserData]);

  if (loading) return <SpinningLoader />;

  return (
    <LayoutInSide>
      <div className="relative max-w-[1000px] mx-auto px-4">
        <div className="py-[30px]">
          <h3 className="text-[32px] font-outfit font-semibold text-textColor tracking-[0.32px]">
            Einstellungen
          </h3>
        </div>

        <Tabs 
          defaultValue={defaultTab} 
          className="space-y-6"
          onValueChange={handleTabChange}
        >
          <TabsList>
            <TabsTrigger value="account" className="flex items-center gap-2" data-testid="account-tab">
              <User className="h-4 w-4" />
              Account
            </TabsTrigger>
            <TabsTrigger value="organization" className="flex items-center gap-2" data-testid="organization-tab">
              <Building2 className="h-4 w-4" />
              Organisation
            </TabsTrigger>
          </TabsList>

          <TabsContent value="account">
            <Card>
              <CardHeader>
                <CardTitle>Account Details</CardTitle>
                <CardDescription>
                  Verwalten Sie Ihre persönlichen Einstellungen
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="space-y-1">
                  <span className="text-sm text-muted-foreground">Vorname & Nachname</span>
                  <p className="text-lg font-medium">{formData.full_name}</p>
                </div>

                <div className="space-y-1">
                  <span className="text-sm text-muted-foreground">Email Addresse</span>
                  <p className="text-lg font-medium">{formData.email}</p>
                </div>

                {!userData.userData?.freetrial && (
                  <div className="flex items-center space-x-2">
                    {upgradeButtonLoading ? (
                      <div className="h-7 w-14 flex items-center justify-center">
                        <MiniSpinningLoader />
                      </div>
                    ) : (
                      <ReactSwitch
                        checked={formData.upgradeButton}
                        onChange={handleUpgradeButtonSwitch}
                        disabled={upgradeButtonLoading}
                        height={28}
                        width={56}
                        handleDiameter={24}
                        offColor="#888888"
                        onColor="#00E047"
                        checkedIcon={
                          <svg height="100%" width="100%" viewBox="-2 -5 17 21" style={{ position: 'absolute', top: 0 }}>
                            <path
                              d="M11.264 0L5.26 6.004 2.103 2.847 0 4.95l5.26 5.26 8.108-8.107L11.264 0"
                              fill="#fff"
                              fillRule="evenodd"
                            />
                          </svg>
                        }
                        uncheckedIcon={
                          <svg viewBox="-2 -5 14 20" height="100%" width="100%" style={{ position: 'absolute', top: 0 }}>
                            <path
                              d="M9.9 2.12L7.78 0 4.95 2.828 2.12 0 0 2.12l2.83 2.83L0 7.776 2.123 9.9 4.95 7.07 7.78 9.9 9.9 7.776 7.072 4.95 9.9 2.12"
                              fill="#fff"
                              fillRule="evenodd"
                            />
                          </svg>
                        }
                      />
                    )}
                    <Label>Upgrade Button anzeigen</Label>
                  </div>
                )}

                {(userData.userData.newsletter?.subscribed === false && formData.newsletter === false) && (
                  <div className="flex items-center space-x-2">
                    {newsletter_loading ? (
                      <div className="h-7 w-14 flex items-center justify-center">
                        <MiniSpinningLoader />
                      </div>
                    ) : (
                      <ReactSwitch
                        checked={formData.newsletter}
                        onChange={handleNewsletterSwitch}
                        disabled={newsletter_loading}
                        height={28}
                        width={56}
                        handleDiameter={24}
                        offColor="#888888"
                        onColor="#00E047"
                        checkedIcon={
                          <svg height="100%" width="100%" viewBox="-2 -5 17 21" style={{ position: 'absolute', top: 0 }}>
                            <path
                              d="M11.264 0L5.26 6.004 2.103 2.847 0 4.95l5.26 5.26 8.108-8.107L11.264 0"
                              fill="#fff"
                              fillRule="evenodd"
                            />
                          </svg>
                        }
                        uncheckedIcon={
                          <svg viewBox="-2 -5 14 20" height="100%" width="100%" style={{ position: 'absolute', top: 0 }}>
                            <path
                              d="M9.9 2.12L7.78 0 4.95 2.828 2.12 0 0 2.12l2.83 2.83L0 7.776 2.123 9.9 4.95 7.07 7.78 9.9 9.9 7.776 7.072 4.95 9.9 2.12"
                              fill="#fff"
                              fillRule="evenodd"
                              data-testid="newsletter-checkbox"
                            />
                          </svg>
                        }
                      />
                    )}
                    <Label>Newsletter und Updates erhalten</Label>
                  </div>
                )}

                <Separator className="my-4" />

                <div className="space-y-4">
                  <Button
                    className="w-full bg-secondary-500 hover:bg-secondary-600"
                    onClick={async (event) => {
                      event.preventDefault();
                      setLoading(true);
                      await manageStripeSubscription();
                    }}
                  >
                    {subscription === false ? "Abonnement upgraden" : "Abonnement verwalten"}
                  </Button>

                  <div className="flex flex-col sm:flex-row justify-center gap-2 sm:gap-4">
                    <Button
                      variant="ghost"
                      className="text-secondary w-full sm:w-auto"
                      onClick={openIubendaPreferences}
                    >
                      Cookie Einstellungen
                    </Button>
                    <Button
                      variant="ghost"
                      className="text-secondary w-full sm:w-auto"
                      onClick={handlePwReset}
                    >
                      Passwort zurücksetzen
                    </Button>
                    <Button
                      variant="ghost"
                      className="text-secondary w-full sm:w-auto"
                      onClick={deleteaccount}
                      data-testid="delete-account-button"
                    >
                      Account löschen
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="organization">
            <OrganizationManager />
          </TabsContent>
        </Tabs>
      </div>
    </LayoutInSide>
  );
}
