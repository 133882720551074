import React, { useEffect } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle, CardDescription } from "../ui/card";
import { Button } from "../ui/button";
import { ChevronDown, ChevronUp, Trash2 } from "lucide-react";

interface InputCardProps {
  title: string;
  isExpanded: boolean;
  onToggle: () => void;
  onNext?: () => void;
  onDelete?: () => void;
  children: React.ReactNode;
  hasContent?: boolean;
  description?: string;
  hideButton?: boolean;
  inputRef?: React.RefObject<HTMLTextAreaElement | HTMLInputElement>;
  isSkeleton?: boolean;
  width?: string;
}

export const InputCard: React.FC<InputCardProps> = ({
  title,
  description,
  isExpanded,
  onToggle,
  onNext,
  onDelete,
  children,
  hasContent = false,
  hideButton = false,
  inputRef,
  isSkeleton = false,
  width,
}) => {
  // Focus input when card expands
  useEffect(() => {
    if (isExpanded && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [isExpanded, inputRef]);

  return (
    <Card onClick={onToggle} className="cursor-pointer mt-4 bg-white">
      <CardHeader className="flex flex-col items-start justify-between">
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center">
            <div className={`w-3.5 h-3.5 rounded-full border border-dashed ${hasContent ? 'bg-green-100 border-green-500' : 'bg-khaki-600 border-khaki-600 '}`}></div>
            {isSkeleton ? (
              <div 
                className="bg-gray-100 h-6 rounded ml-2 pl-2"
                style={{ width: width || '32px' }}
              ></div>
            ) : (
              <CardTitle className="text-base font-medium ml-2 pl-2">{title}</CardTitle>
            )}
          </div>
          <div className="pointer-events-none">
            {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
          </div>
        </div>
        {isExpanded && (
          <CardDescription className="text-grey pt-4">{description}</CardDescription>
        )}
      </CardHeader>
      {isExpanded && (
        <>
          <CardContent onClick={(e) => e.stopPropagation()}>{children}</CardContent>
          <CardFooter className="flex justify-between items-center" onClick={(e) => e.stopPropagation()}>
            {onDelete && (
              <Button
                variant="ghost"
                size="icon"
                onClick={
                  (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onDelete();
                  }
                }
                className="h-8 w-8"
                data-testid="delete-input-button"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            )}
            <div className="flex-grow" />
            {onNext && !hideButton && (
              <Button 
                onClick={onNext}
                variant={hasContent ? "default" : "secondary"}
              >
                {"Weiter"}
              </Button>
            )}
          </CardFooter>
        </>
      )}
    </Card>
  );
};