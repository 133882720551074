import LayoutInSide from "../../layouts/LayoutInSide";
import { useLocation } from "react-router-dom";
import { useRef, useState, useEffect, useCallback } from "react";
import firebase from "../../config/fbConfig";
import { toast } from "react-toastify";
import SpinningLoader from "../../components/loader/spinning_loader";
import { httpsCallable } from "firebase/functions";
import {
  firestore,
  functions,
  doc,
  getDoc,
  onSnapshot,
  auth,
} from "../../config/fbConfig"
import { setStopPrompt } from "../../utils/userUtils";
import { usePostHog } from 'posthog-js/react';
import Cookies from 'js-cookie'

// Modular Fireabse SDK imports
import { collection, setDoc, getDocs, query, where } from 'firebase/firestore';
import { useNavigate } from "react-router-dom";
import { DropdownSelect } from "../../components/inputs/DropdownSelect";

// Shadcn Card
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card"

// Shadcn Input
import { Input } from "../../components/ui/input"
import { Textarea } from "../../components/ui/textarea"

// Upgrade Modal
import UpgradeModal from "../../layouts/Modal/UpgradeModal";

// New InputCard component
import { InputCard } from "../../components/cards/InputCard";

// react-hook-form
import { useForm } from "react-hook-form";

// UI Button
import { Button } from "../../components/ui/button";
import { ArrowLeft, Loader2, Trash2, X } from "lucide-react";

// DocumentEditor component
import { DocumentEditor } from '../../components/editor/DocumentEditor';
import { DocumentEditorRef } from '../../components/editor/DocumentEditor';
import { useUserData } from '../../providers/UserDataProvider';
import { AIService } from '../../services/AIService';

// GenerateButton component
import { GenerateButton } from "../../components/inputs/GenerateButton";

// Suggestion components
import { SuggestionChip, SuggestionTitle } from "../../components/inputs/SuggestionChip";

// Additional Information Card
import { AdditionalInformationCard } from "../../components/inputs/AdditionalInformationCard";

// Firebase Function Reference
const resettemplate = httpsCallable(functions, "resettemplate_py");


const TemplateDetailPage = () => {

  // Constants
  const max_input_length = 2000;
  const languages = [
    { value: 'German', label: 'Deutsch' },
    { value: 'English', label: 'English' },
    { value: 'French', label: 'Französisch' },
    { value: 'Spanish', label: 'Spanisch' },
    { value: 'Italian', label: 'Italienisch' },
    { value: 'Portuguese', label: 'Portugisisch' },
    { value: 'Dutch', label: 'Niederländisch' },
    { value: 'Swedish', label: 'Schwedisch' },
    { value: 'Danish', label: 'Dänisch' },
    { value: 'Norwegian', label: 'Norwegisch' },
    { value: 'Finnish', label: 'Finnisch' },
    { value: 'Polish', label: 'Polnisch' },
    { value: 'Russian', label: 'Russisch' },
    { value: 'Turkish', label: 'Türkisch' },
  ];

  // State Variables
  const [generatedText, setGeneratedText] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState(false);
  const [cancel, setCancel] = useState(false);
  const cancelRef = useRef(false);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [templateEditable, setTemplateEditable] = useState(false);
  const [,setAllowEdit] = useState(false);
  const [textLength, setTextLength] = useState(0);
  const [error, setError] = useState("");
  const [language, setLanguage] = useState('German');
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [activeTab, setActiveTab] = useState<'input' | 'output'>('input');
  const [showBottomNav, setShowBottomNav] = useState(false);
  const editorRef = useRef<DocumentEditorRef>(null);
  const [answerLength, setAnswerLength] = useState('Normal');
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [editorLoading, setEditorLoading] = useState(true);
  const [stopping, setStopping] = useState(false);

  // Providers
  const posthog = usePostHog();
  const navigate = useNavigate();
  const { personas, templates, userData, defaultPersonaId } = useUserData();

  const [persona, setPersona] = useState('');

  // References
  const [template, setTemplate] = useState<any>(null);
  const location = useLocation();

  // Unique generation id, used to track the generation
  // its a combination of the user id and the current timestamp
  // its assigned a value every time a generation is started
  let generation_id = "";

  // New state for expanded card index
  const [expandedCardIndex, setExpandedCardIndex] = useState(0);

  // Initialize formValues with react-hook-form
  const { register, handleSubmit, watch, setValue } = useForm();

  const aiService = useRef<AIService>(new AIService({
    editorRef,
    userId: auth.currentUser!.uid,
    posthog
  }));

  // Add this constant for excluded template IDs
  const EXCLUDED_TEMPLATE_IDS = ['A9T8o6KZ2jI9LkQP1SsG'];

  // List of templates with the answer length option
  const TEMPLATES_WITH_ANSWER_LENGTH_OPTION = ['D0K0o9RY1xJ4CpQT8Yr5']; // Add template names here

  // Add this constant near the other constants at the top
  const TEMPLATES_WITHOUT_ADDITIONAL_INFO = ['A9T8o6KZ2jI9LkQP1SsG', 'D0K0o9RY1xJ4CpQT8Yr5'];

  // Add this helper function
  const shouldShowDropdowns = () => {
    return !template?.id || !EXCLUDED_TEMPLATE_IDS.includes(template.id);
  };

  // Add new state for input generation
  const [generatingInputs, setGeneratingInputs] = useState<Record<string, boolean>>({});

  // Add state for suggestions
  const [suggestions, setSuggestions] = useState<Record<string, string[]>>({});
  const [loadingSuggestions, setLoadingSuggestions] = useState<Record<string, boolean>>({});

  // Add these new state variables after other state declarations
  const [additionalFields, setAdditionalFields] = useState<Array<{
    label: string;
    type: string;
    description: string;
  }>>([]);
  const [loadingAdditionalFields, setLoadingAdditionalFields] = useState(false);
  const [suggestedAdditionalFields, setSuggestedAdditionalFields] = useState<Array<{
    title: string;
    description: string;
    type: "input" | "textarea";
  }>>([]);

  // Add this state to track the last added field index
  const [lastAddedFieldIndex, setLastAddedFieldIndex] = useState<number | null>(null);

  // Add suggestion handler
  const handleLoadSuggestions = useCallback(async (index: number, fieldInfo: any) => {
    const isAdditionalField = index >= template.placeholders.length;
    const fieldName = isAdditionalField ? `additional_${index - template.placeholders.length}` : `input_${index}`;
    
    // Always generate new suggestions when called
    setLoadingSuggestions(prev => ({ ...prev, [fieldName]: true }));
    
    try {
      // Get all current input values
      const currentValues = watch();
      const otherInputs: Record<string, string> = {};
      
      // Build other inputs context
      Object.entries(currentValues).forEach(([key, value]) => {
        if (key !== fieldName && value) {
          if (key.startsWith('input_')) {
            const fieldIndex = parseInt(key.split('_')[1]);
            const fieldLabel = template.placeholders[fieldIndex].label;
            otherInputs[fieldLabel] = value as string;
          } else if (key.startsWith('additional_')) {
            const fieldIndex = parseInt(key.split('_')[1]);
            const field = additionalFields[fieldIndex];
            otherInputs[field.label] = value as string;
          }
        }
      });

      // Get suggestions
      const fieldSuggestions = await aiService.current.generateInputSuggestions({
        template: {
          title: template.title,
          description: template.description,
          prompt: template.prompt
        },
        fieldInfo: {
          label: fieldInfo.label,
          description: fieldInfo.description,
          type: fieldInfo.type
        },
        otherInputs,
        language
      });

      // Add data attribute to help with testing
      setSuggestions(prev => ({ ...prev, [fieldName]: fieldSuggestions }));
      
    } catch (error) {
      console.error('Error generating suggestions:', error);
      toast.error('Failed to generate suggestions');
    } finally {
      setLoadingSuggestions(prev => ({ ...prev, [fieldName]: false }));
    }
  }, [template, additionalFields, watch, language]);

  // Update the effect to handle suggestion generation
  useEffect(() => {
    if (lastAddedFieldIndex !== null) {
      // First close the additional info card
      setExpandedCardIndex(-1);
      // Then open the new field after a short delay
      const timer = setTimeout(() => {
        setExpandedCardIndex(lastAddedFieldIndex);
        // Get the field info to check if it's an input type
        const fieldInfo = lastAddedFieldIndex >= template.placeholders.length
          ? additionalFields[lastAddedFieldIndex - template.placeholders.length]
          : template.placeholders[lastAddedFieldIndex];
        
        // Generate suggestions if it's an input field
        if (fieldInfo?.type === "input") {
          handleLoadSuggestions(lastAddedFieldIndex, fieldInfo);
        }
        
        setLastAddedFieldIndex(null); // Reset the tracking state
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [lastAddedFieldIndex, template?.placeholders, additionalFields, handleLoadSuggestions]);

  // Update the handlers to use the new state
  const handleAddAdditionalField = useCallback((field: {
    title: string;
    description: string;
    type: "input" | "textarea";
  }) => {
    setAdditionalFields(prev => {
      const newFields = [...prev, {
        label: field.title,
        type: field.type,
        description: field.description
      }];
      // Calculate and store the new field's index
      const newFieldGlobalIndex = template.placeholders.length + newFields.length - 1;
      setLastAddedFieldIndex(newFieldGlobalIndex);

      // Track the event in PostHog
      posthog.capture('template_detail_page:add_field', {
        template_id: template?.id,
        template_title: template?.title,
        field_type: field.type,
        field_title: field.title,
        field_index: newFieldGlobalIndex,
        total_fields: template.placeholders.length + newFields.length
      });

      return newFields;
    });
  }, [template?.placeholders?.length, template?.id, template?.title, posthog]);

  const handleAddContext = useCallback(() => {
    setAdditionalFields(prev => {
      const newFields = [...prev, {
        label: "Kontext",
        type: "textarea",
        description: "Füge weiteren Kontext an die Vorlage an"
      }];
      // Calculate and store the new field's index
      const newFieldGlobalIndex = template.placeholders.length + newFields.length - 1;
      setLastAddedFieldIndex(newFieldGlobalIndex);

      // Track the event in PostHog
      posthog.capture('template_detail_page:add_context', {
        template_id: template?.id,
        template_title: template?.title,
        field_index: newFieldGlobalIndex,
        total_fields: template.placeholders.length + newFields.length
      });

      return newFields;
    });
  }, [template?.placeholders?.length, template?.id, template?.title, posthog]);

  const generateAdditionalFields = useCallback(async () => {
    setLoadingAdditionalFields(true);
    try {
      // Get all current input values for context
      const currentValues = watch();
      const otherInputs: Record<string, string> = {};
      
      Object.entries(currentValues).forEach(([key, value]) => {
        if (value) {
          const fieldIndex = parseInt(key.split('_')[1]);
          const fieldLabel = template?.placeholders[fieldIndex]?.label;
          if (fieldLabel) {
            otherInputs[fieldLabel] = value as string;
          }
        }
      });

      // Use the same AIService instance to generate suggestions
      const suggestions = await aiService.current.generateAdditionalFields({
        template: {
          title: template?.title,
          description: template?.description,
          prompt: template?.prompt
        },
        currentInputs: otherInputs
      });

      setSuggestedAdditionalFields(suggestions);
    } catch (error) {
      console.error('Error generating additional fields:', error);
      toast.error('Failed to generate additional field suggestions');
    } finally {
      setLoadingAdditionalFields(false);
    }
  }, [template, watch]);

  // Add generation handler
  const handleGenerateInput = async (index: number, fieldInfo: any) => {
    const isAdditionalField = index >= template.placeholders.length;
    const fieldName = isAdditionalField ? `additional_${index - template.placeholders.length}` : `input_${index}`;
    
    // Set generating state for this field
    setGeneratingInputs(prev => ({ ...prev, [fieldName]: true }));
    
    try {
      // Get all current input values
      const currentValues = watch();
      const otherInputs: Record<string, string> = {};
      
      // Build other inputs context
      Object.entries(currentValues).forEach(([key, value]) => {
        if (key !== fieldName && value) {
          if (key.startsWith('input_')) {
            const fieldIndex = parseInt(key.split('_')[1]);
            const fieldLabel = template.placeholders[fieldIndex].label;
            otherInputs[fieldLabel] = value as string;
          } else if (key.startsWith('additional_')) {
            const fieldIndex = parseInt(key.split('_')[1]);
            const field = additionalFields[fieldIndex];
            otherInputs[field.label] = value as string;
          }
        }
      });

      // Clear existing content
      setValue(fieldName, '');
      let accumulatedContent = '';

      // Start the generation
      const stream = await aiService.current.generateInputContent({
        template: {
          title: template.title,
          description: template.description,
          prompt: template.prompt
        },
        fieldInfo: {
          label: fieldInfo.label,
          description: fieldInfo.description,
          type: fieldInfo.type
        },
        otherInputs,
        language
      });

      // Process the stream
      const reader = stream.getReader();
      
      try {
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          
          accumulatedContent += value;
          
          // Update the form field with new content immediately
          setValue(fieldName, accumulatedContent, { 
            shouldDirty: true,
            shouldTouch: true
          });

          // Force immediate update of the textarea
          const element = document.querySelector(`[name="${fieldName}"]`) as HTMLTextAreaElement;
          if (element) {
            element.value = accumulatedContent;
            element.dispatchEvent(new Event('input', { bubbles: true }));
          }
        }

        // After stream is complete, update word count
        const words = accumulatedContent.split(/\s+/).filter(word => word !== "").length;
        setTextLength(words);
        
        if (words > max_input_length) {
          setError(
            `Du benutzt zu viele Wörter in der Eingabe. Das Maximum wird um: ${words - max_input_length
            } Wörter überschritten.`
          );
        } else {
          setError("");
        }
        
      } catch (error) {
        if (String(error).includes("Candidate was blocked due to SAFETY")) {
          toast.error("Ups, etwas ist schief gelaufen. Bitte versuche es mit einer anderen Eingabe erneut.");
          return;
        }
        console.error('Error reading from stream:', error);
        throw error;
      } finally {
        reader.releaseLock();
      }
      
    } catch (error) {
      console.error('Error generating input:', error);
      if (String(error).includes("Candidate was blocked due to SAFETY")) {
        toast.error("Ups, etwas ist schief gelaufen. Bitte versuche es mit einer anderen Eingabe erneut.");
        return;
      }
      toast.error('Failed to generate input content');
    } finally {
      setGeneratingInputs(prev => ({ ...prev, [fieldName]: false }));
    }
  };

  // Add this useEffect to handle initial suggestions for first input
  useEffect(() => {
    if (template?.placeholders && template.placeholders.length > 0) {
      const firstField = template.placeholders[0];
      if (firstField.type === "input") {
        handleLoadSuggestions(0, firstField);
      }
    }
  }, [template]);

  const loadUserTemplates = async () => {
    setLoadingTemplates(true);
    if (!auth.currentUser) {
      navigate("/sign-in");
      return;
    }

    // Check if template was passed via location state
    if (location.state?.name) {
      setTemplate(location.state);
      // Check if template type is "Persönliche Vorlage"
      if (location.state?.type === "Persönliche Vorlagen") {
        setTemplateEditable(true);
        setAllowEdit(true);
      }
    } else {
      // Get template name from URL path
      const templateName = decodeURIComponent(location.pathname.split('/').pop() || '');
      if (!templateName) {
        navigate("/templates");
        return;
      }

      // Find template in templates array from UserDataProvider
      const loadedTemplate = templates.find(t => t.title === templateName);
      
      if (!loadedTemplate) {
        navigate("/templates");
        return;
      }
      setTemplate(loadedTemplate);
      // Check if template type is "Persönliche Vorlage"
      if (loadedTemplate.type === "Persönliche Vorlagen") {
        setTemplateEditable(true);
        setAllowEdit(true);
      }
    }
    setLoadingTemplates(false);
  };

  const loadOutputField = async () => {
    if (auth.currentUser) {
      const userDocRef = doc(collection(firestore, "users"), auth.currentUser!.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        // Set up real-time listener
        onSnapshot(userDocRef, (userDocSnap) => {
          if (userDocSnap.exists()) {
            setLoading(false);
            const userDocData = userDocSnap.data();
            if (Cookies.get('useFallback') !== 'true') {
              setGeneratedText(userDocData.currentresponse);
            }
          } else {
            console.error("User document not found");
          }
        });
      } else {
        console.error("User document not found");
      }
    } else {
      console.error("User not authenticated");
    }
  };

  const renderInputCard = (field: any, index: number, isAdditionalField: boolean = false) => {
    const actualIndex = isAdditionalField ? index + template.placeholders.length : index;
    const fieldName = isAdditionalField ? `additional_${index}` : `input_${index}`;
    const value = watch(fieldName) || '';
    const hasContent = value.trim() !== '';
    const isExpanded = actualIndex === expandedCardIndex;
    const isLast = !isAdditionalField && index === template.placeholders.length - 1;
    const hasNext = isAdditionalField ? 
      index < additionalFields.length - 1 : 
      index < template.placeholders.length - 1;

    return (
      <InputCard
        key={fieldName}
        title={field.label}
        description={field.description}
        isExpanded={isExpanded}
        onToggle={() => {
          if (isExpanded) {
            setExpandedCardIndex(-1);
          } else {
            setExpandedCardIndex(actualIndex);
            if (field.type === "input") {
              handleLoadSuggestions(actualIndex, field);
            }
          }
        }}
        hasContent={hasContent}
        onNext={hasNext ? () => {
          const nextIndex = actualIndex + 1;
          setExpandedCardIndex(nextIndex);
          const nextField = isAdditionalField ? 
            additionalFields[index + 1] : 
            template.placeholders[index + 1];
          if (nextField?.type === "input") {
            handleLoadSuggestions(nextIndex, nextField);
          }
          // Focus the next input field
          const nextFieldName = isAdditionalField ? `additional_${index + 1}` : `input_${index + 1}`;
          const nextFieldElement = document.querySelector(`[name="${nextFieldName}"]`);
          if (nextFieldElement instanceof HTMLElement) {
            nextFieldElement.focus();
          }
        } : undefined}
        hideButton={isLast}
        onDelete={isAdditionalField ? () => {
          // Track the deletion event in PostHog
          posthog.capture('template_detail_page:remove_field', {
            template_id: template?.id,
            template_title: template?.title,
            field_type: field.type,
            field_title: field.label,
            field_index: actualIndex,
            total_fields: template.placeholders.length + additionalFields.length - 1,
            had_content: hasContent
          });

          setAdditionalFields(prev => prev.filter((_, i) => i !== index));
        } : undefined}
      >
        <div className="relative">
          {field.type === "textarea" ? (
            <>
              <p className="text-[13px] text-gray-1000 font-medium font-poppins mb-1">
                <span>{textLength}</span>/{max_input_length}
              </p>
              <Textarea
                className="cflex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 focus:border-0 focus:ring-purple-500"
                maxLength={Number.MAX_SAFE_INTEGER}
                style={{ height: '200px', resize: 'vertical', paddingRight: '2.5rem' }}
                placeholder="Text"
                tabIndex={actualIndex + 1}
                onKeyDown={(e) => {
                  if (e.key === 'Tab') {
                    e.preventDefault();
                    if (isLast) {
                      if (allFieldsFilled) {
                        handleSubmit(onSubmitHandler)();
                      }
                    } else {
                      const nextIndex = actualIndex + 1;
                      setExpandedCardIndex(nextIndex);
                      setTimeout(() => {
                        const nextFieldName = isAdditionalField ? `additional_${index + 1}` : `input_${index + 1}`;
                        const nextField = document.querySelector(`[name="${nextFieldName}"]`);
                        if (nextField instanceof HTMLElement) {
                          nextField.focus();
                        }
                      }, 0);
                    }
                  }
                }}
                {...register(fieldName, {
                  onChange: (e) => onChangeHandler(e),
                })}
              />
              {value && (
                <button
                  className="absolute top-[1.75rem] right-2 mr-3"
                  tabIndex={-1}
                  onClick={(e) => {
                    e.preventDefault();
                    onClearHandler(index, isAdditionalField);
                  }}
                >
                  <X className="h-4 w-4 text-blue-500" />
                </button>
              )}
              <GenerateButton
                data-testid="generate-input-button"
                isGenerating={generatingInputs[fieldName]}
                hasGenerated={hasContent}
                onClick={(e) => {
                  e.preventDefault();
                  handleGenerateInput(actualIndex, field);
                }}
              />
            </>
          ) : (
            <>
              <Input
                maxLength={Number.MAX_SAFE_INTEGER}
                placeholder="Text"
                style={{ paddingRight: '1.5rem' }}
                tabIndex={actualIndex + 1}
                onKeyDown={(e) => {
                  if (e.key === 'Tab') {
                    e.preventDefault();
                    if (isLast) {
                      if (allFieldsFilled) {
                        handleSubmit(onSubmitHandler)();
                      }
                    } else {
                      const nextIndex = actualIndex + 1;
                      setExpandedCardIndex(nextIndex);
                      setTimeout(() => {
                        const nextFieldName = isAdditionalField ? `additional_${index + 1}` : `input_${index + 1}`;
                        const nextField = document.querySelector(`[name="${nextFieldName}"]`);
                        if (nextField instanceof HTMLElement) {
                          nextField.focus();
                        }
                      }, 0);
                    }
                  }
                }}
                {...register(fieldName, {
                  onChange: (e) => onChangeHandler(e),
                })}
                className="focus:border-0 focus:ring-purple-500"
              />
              {value && (
                <button
                  tabIndex={-1}
                  onClick={(e) => {
                    e.preventDefault();
                    onClearHandler(index, isAdditionalField);
                  }}
                  className="absolute top-[0.88rem] right-[0.625rem]"
                >
                  <X className="h-4 w-4 text-blue-500" />
                </button>
              )}
              {isExpanded && (
                <div className="mt-2" data-testid="suggestions-container">
                  {loadingSuggestions[fieldName] ? (
                    <div className="flex items-center gap-1.5 text-xs text-primary" data-testid="suggestions-loading">
                      <Loader2 className="h-3 w-3 animate-spin text-primary" />
                      <span>Generiere Vorschläge...</span>
                    </div>
                  ) : suggestions[fieldName]?.length > 0 ? (
                    <>
                      <SuggestionTitle />
                      <div className="space-y-1" data-testid="suggestions-list">
                        {suggestions[fieldName].map((suggestion, i) => (
                          <SuggestionChip
                            key={i}
                            index={i}
                            suggestion={suggestion}
                            onClick={() => {
                              setValue(fieldName, suggestion, {
                                shouldDirty: true,
                                shouldTouch: true,
                                shouldValidate: false
                              });
                              const element = document.querySelector(`[name="${fieldName}"]`) as HTMLInputElement;
                              if (element) {
                                element.value = suggestion;
                                element.dispatchEvent(new Event('input', { bubbles: true }));
                                element.focus();
                              }
                            }}
                          />
                        ))}
                      </div>
                    </>
                  ) : null}
                </div>
              )}
            </>
          )}
        </div>
        <p className={`text-red-500 mt-5 ${error ? 'visible' : 'hidden'}`}>{error}</p>
      </InputCard>
    );
  };

  const renderInputs = () => {
    if (loadingTemplates || !template) {
      return <SpinningLoader />
    }
    const placeholders = template.placeholders;

    return (
      <>
        {/* Original inputs */}
        {placeholders.map((placeholder: any, index: number) => renderInputCard(placeholder, index))}

        {/* Additional fields */}
        {additionalFields.map((field, index) => renderInputCard(field, index, true))}

        {/* Only show Additional Information card for templates not in the exclusion list */}
        {!TEMPLATES_WITHOUT_ADDITIONAL_INFO.includes(template.id) && (
          <>
            {/* Divider before Additional Information card */}
            <div className="h-px w-full bg-gray-200 my-4"></div>

            {/* Additional Information Card */}
            <AdditionalInformationCard
              onAddField={handleAddAdditionalField}
              onAddContext={handleAddContext}
              isLoading={loadingAdditionalFields}
              suggestedFields={suggestedAdditionalFields}
              isExpanded={expandedCardIndex === -2}
              onToggle={() => {
                if (expandedCardIndex === -2) {
                  setExpandedCardIndex(-1);
                } else {
                  setExpandedCardIndex(-2);
                  generateAdditionalFields();
                }
              }}
              existingFields={[
                ...template.placeholders.map((p: any) => ({
                  label: p.label,
                  type: p.type,
                  description: p.description
                })),
                ...additionalFields
              ]}
            />
          </>
        )}
      </>
    );
  };

  const onChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const allValues = watch();
    let totalValue = Object.values(allValues).join(' ');
    const totalWordsCount = totalValue
      .split(/\s+/)
      .filter((word) => word !== "").length;

    setTextLength(totalWordsCount);

    if (totalWordsCount > max_input_length) {
      setError(
        `Du benutzt zu viele Wörter in der Eingabe. Das Maximum wird um: ${totalWordsCount - max_input_length
        } Wörter überschritten.`
      );
      e.target.style.border = '1px solid red';
      posthog.capture('template_detail_page:character_limit_reached', {
        generation_id: generation_id,
        template: template?.title,
        word_cnt: totalWordsCount,
      });
    } else {
      e.target.style.border = '';
      setError("");
    }
  }, [watch, generation_id, template?.title, posthog]);

  const onClearHandler = useCallback((index: number, isAdditionalField: boolean = false) => {
    const fieldName = isAdditionalField ? `additional_${index}` : `input_${index}`;
    // Clear the value using react-hook-form's setValue
    setValue(fieldName, '');
  }, [setValue]);

  const onSubmitHandler = async (data: any) => {
    // Prevent submission if already loading or stopping
    if (loading || stopping) return;

    // Check if user has remaining creations
    const hasNoCreationsLeft = userData?.userData?.creations <= 0 && userData?.userData?.bonus_creations <= 0;
    const isNotUnlimited = !userData?.userData?.unlimited;

    if (hasNoCreationsLeft && isNotUnlimited) {
      setShowUpgradeModal(true);
      return;
    }
    
    // Check if we're trying to cancel
    if (!cancel) {
      setStopping(true);
      if (Cookies.get('useFallback') === 'true') {
        aiService.current.cancel();
      } else {
        await setStopPrompt(auth.currentUser!.uid);
      }
      setStopping(false);
      return;
    }

    setLoading(true);
    setShowBottomNav(true);
    setActiveTab('output');

    try {
      cancelRef.current = false;
      setCancel(false);

      // Get values from original template fields
      const inputValues = template?.replaceValues.map(
        (_: any, index: number) => data[`input_${index}`] || ''
      );

      // Get values from additional fields
      const additionalValues = additionalFields.map((field, index) => ({
        label: field.label,
        value: data[`additional_${index}`] || ''
      }));

      // Add additional fields to the prompt
      let prompt = template?.prompt;
      template?.replaceValues.forEach((values: any, index: number) => {
        prompt = prompt?.replace(`{${values}}`, inputValues[index]);
      });

      // Append additional fields to the prompt
      if (additionalValues.length > 0) {
        prompt += "\n\nZusätzliche Informationen:\n";
        additionalValues.forEach(({ label, value }) => {
          if (value) {
            prompt += `\n${label}: ${value}`;
          }
        });
      }

      let systemPrompt = `
        Schreibe deine Antwort in folgender Sprache: ${shouldShowDropdowns() ? language : 'German'}.
        Formatiere deine Antwort immer in Markdown! Spezifisch nach dem CommonMark Standard.
        Wenn angemessen, beginne die Antwort mit einem Header (## Header).
        ${shouldShowDropdowns() && persona ? `Schreibe im folgenden Tonfall: ${persona}` : ''}
        ${shouldShowDropdowns() && answerLength === 'Kurz' 
          ? 'Halte die Antwort kurz und prägnant.' 
          : shouldShowDropdowns() && answerLength === 'Lang' 
            ? 'Schreibe eine sehr lange Antwort. Gehe in die Tiefe und erkläre alle relevanten Aspekte.' 
            : ''}
      `;
      
      sessionStorage.setItem('copyEventFired', 'false');

      await aiService.current.generate({
        prompt,
        systemPrompt,
        template: template?.title
      });

      cancelRef.current = true;
      setCancel(true);

    } catch (error) {
      console.error(error);
      cancelRef.current = true;
      setCancel(true);
    } finally {
      setLoading(false);
    }
  };

  const setLanguagefromFB = async () => {
    const writeDataDocRef = doc(collection(firestore, "users", auth.currentUser!.uid, "writedata"), 'appdata');
    const docSnapshot = await getDoc(writeDataDocRef);
    if (docSnapshot.exists()) {
      const currentState = docSnapshot.data();
      setLanguage(currentState.language || 'German');
    } else {
      setLanguage('German');
    }
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === 'create-tone') {
      navigate('/tonfall');
    }
  };

  /* UseEffect Hooks */

  useEffect(() => {
    setCancel(cancelRef.current);   // Update state to trigger re-render
  }, []);

  // Posthog Event for template_detail_page:template_loaded
  useEffect(() => {
    if (template) {
      posthog.capture('template_detail_page:template_loaded', {
        template: template.title
      });
    }
  }, [template, posthog]);

  // UseEffect to initialize the detail page
  useEffect(() => {
    cancelRef.current = true
    setCancel(true);   // Update state to trigger re-render
    loadUserTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    resettemplate().then(() => {
      loadOutputField();
    });

    // Setup onSnapshot listener for cancel signal
    const docRef = firebase.firestore().collection("users").doc(auth.currentUser!.uid);
    docRef.onSnapshot((doc: any) => {
      const data = doc.data();
      if (data && data.currentlywriting !== undefined && (Cookies.get('useFallback') !== 'true')) {
        cancelRef.current = !data.currentlywriting;
        setCancel(!data.currentlywriting);   // Update state to trigger re-render
      }
    });

    setLanguagefromFB()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAnswerLengthChange = (value: string) => {
    setAnswerLength(value);
  };

  // Add this function near your other Firebase-related functions
  const getTemplateIdByName = async (templateName: string) => {
    const templatesRef = collection(firestore, "users", auth.currentUser!.uid, "templates");
    const q = query(templatesRef, where("template_data.title", "==", templateName));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].id;
    }
    return null;
  };

  // Add this useEffect to watch form changes
  useEffect(() => {
    // Subscribe to form changes
    const subscription = watch((value, { name, type }) => {
      const values = Object.values(value);
      const isFilled = values.length > 0 && values.every(val => val && String(val).trim() !== '');
      setAllFieldsFilled(isFilled);
    });
    
    // Cleanup subscription on unmount
    return () => subscription.unsubscribe();
  }, [watch]);

  // Add this function to handle editor initialization
  const handleEditorInit = () => {
    setEditorLoading(false);
  };

  // Add this useEffect to focus the first input when component mounts
  useEffect(() => {
    // Set the first card to be expanded
    setExpandedCardIndex(0);
    
    // Focus the first input field after a short delay to ensure the DOM is ready
    setTimeout(() => {
      const firstInput = document.querySelector('input[name="input_0"], textarea[name="input_0"]');
      if (firstInput instanceof HTMLElement) {
        firstInput.focus();
      }
    }, 100);
  }, []);

  // Add this function near your other handlers
  const handleLanguageChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);

    // Update language preference in Firestore
    try {
      const writeDataDocRef = doc(collection(firestore, "users", auth.currentUser!.uid, "writedata"), 'appdata');
      await setDoc(writeDataDocRef, { language: newLanguage }, { merge: true });
    } catch (error) {
      console.error("Error updating language preference:", error);
      toast.error("Failed to update language preference");
    }
  };

  // Update useEffect to set initial persona based on default
  useEffect(() => {
    if (defaultPersonaId) {
      const defaultPersona = personas.find(p => p.id === defaultPersonaId);
      if (defaultPersona) {
        setPersona(defaultPersona.personatext);
      } else {
        setPersona('none');
      }
    } else {
      setPersona('none');
    }
  }, [defaultPersonaId, personas]);

  if (loadingTemplates) {
    return <SpinningLoader />
  } else {
    return (
      <>
        <LayoutInSide>
          <form 
            onSubmit={handleSubmit(onSubmitHandler)} 
            className="h-full flex-1 flex flex-col"
            // Add these attributes to ensure proper form behavior
            method="POST"
            action="#"
          >
            {/* Main content area */}
            <div className="flex-grow flex flex-col lg:flex-row overflow-hidden">
              {/* Input Column */}
              <div className={`w-full lg:w-1/3 flex-shrink-0 ${activeTab === 'input' ? 'block' : 'hidden lg:block'}`}>
                <div className="h-full overflow-y-auto custom-scrollbar mx-4 mt-4 pb-20 lg:pb-4">
                  {/* Header Card */}
                  <Card className="border-gray-200 shadow-none mb-4 bg-white">
                    {/* Header & Subtitle */}
                    <CardHeader className="flex items-left">
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <div className="lg:hidden mr-4">
                            <Button
                              variant="ghost"
                              onClick={() => navigate('/templates')}
                              className="p-0"
                            >
                              <ArrowLeft className="h-6 w-6" />
                            </Button>
                          </div>
                          <CardTitle>{template?.title}</CardTitle>
                        </div>
                        <CardDescription className="mt-2">{template?.description}</CardDescription>
                      </div>
                    </CardHeader>
                    <CardContent>
                      <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        {shouldShowDropdowns() && (
                          <>
                            <li className="col-span-1">
                              <DropdownSelect
                                options={languages}
                                value={language}
                                onChange={(value) => {
                                  setLanguage(value);
                                  handleLanguageChange({ target: { value } } as React.ChangeEvent<HTMLSelectElement>);
                                }}
                                placeholder="Deutsch"
                                className="min-h-[2.5rem] truncate"
                              />
                            </li>
                            <li className="col-span-1">
                              <DropdownSelect
                                options={[
                                  { 
                                    value: 'none', 
                                    // Add star to "Kein Tonfall" if there's no standard persona
                                    label: (!personas.some(p => p.standard) ? '★ ' : '') + 'Tonfall anpassen'
                                  },
                                  ...personas.map(p => ({ 
                                    value: p.personatext, 
                                    // Show star if this persona is the default one
                                    label: (p.id === defaultPersonaId ? '★ ' : '') + (p.name.length > 25 ? p.name.substring(0, 25) + '...' : p.name),
                                    isDefault: p.id === defaultPersonaId
                                  })),
                                  { value: 'create-tone', label: '+ Tonfall erstellen' }
                                ]}
                                value={persona}
                                onChange={(value) => {
                                  if (value === 'create-tone') {
                                    navigate('/tonfall');
                                  } else {
                                    setPersona(value);
                                    handleSelectChange({ target: { value } } as React.ChangeEvent<HTMLSelectElement>);
                                  }
                                }}
                                placeholder="Tonfall"
                                className="min-h-[2.5rem] truncate"
                              />
                            </li>
                            {!TEMPLATES_WITH_ANSWER_LENGTH_OPTION.includes(template?.id) && (
                              <li className={`col-span-${templateEditable ? '1' : '2'}`}>
                                <DropdownSelect
                                  options={[
                                    { value: 'Normal', label: 'Normal' },
                                    { value: 'Kurz', label: 'Kurz' },
                                    { value: 'Lang', label: 'Lang' }
                                  ]}
                                  value={answerLength}
                                  onChange={handleAnswerLengthChange}
                                  placeholder="Textlänge"
                                  className="min-h-[2.5rem] truncate"
                                />
                              </li>
                            )}
                            {/* Edit Template Buttons */}
                            {templateEditable && (
                              <li className="col-span-1">
                                <Button 
                                  variant="outline" 
                                  className="w-full text-center min-h-[2.5rem] whitespace-normal text-sm py-1"
                                  data-testid="template-edit-button"
                                  onClick={async (e) => {
                                    e.preventDefault(); // Prevent form submission
                                    const templateId = await getTemplateIdByName(template?.title);
                                    if (templateId) {
                                      navigate(`/edit/${templateId}`);
                                    } else {
                                      toast.error("Template nicht gefunden");
                                    }
                                  }}
                                >
                                  Vorlage Bearbeiten
                                </Button>
                              </li>
                            )}
                          </>
                        )}
                      </ul>
                    </CardContent>
                  </Card>

                  {/* Divider */}
                  <div className="h-px w-full bg-gray-200 my-4"></div>

                  {/* Render Placeholders */}
                  {renderInputs()}

                  {/* Divider */}
                  <div className="h-px w-full bg-gray-200 my-4"></div>

                  {/* Start and Stop Buttons */}
                  <div className={`flex md:left-[13rem] justify-center mt-auto px-4 ${showBottomNav ? 'fixed bottom-12 left-0 right-0 lg:static pb-4' : 'fixed bottom-0 left-0 right-0 lg:static lg:mt-4 pb-4'}`}>
                    <Button
                      type="submit"
                      variant={cancel && allFieldsFilled ? "default" : "secondary"}
                      data-testid={cancel ? "start-button" : "stop-button"}
                      className="w-full lg:w-auto lg:min-w-[200px]"
                      disabled={loading}
                    >
                      {cancel ? "Start" : "Stop"}
                    </Button>
                  </div>

                  {/* Add padding at the bottom to prevent content from being hidden behind the fixed button */}
                  <div className="pb-20" />

                </div>
              </div>

              {/* Output Column */}
              <div
                className={`w-full lg:w-2/3 flex flex-col flex-grow overflow-hidden ${activeTab === 'output' ? 'block' : 'hidden lg:block'
                  }`}
              >

                {/* Output Field Box */}
                <div className={`lg:pt-4 lg:flex-grow flex flex-col h-[calc(100vh-100px)] lg:h-full overflow-y-auto ${showBottomNav ? 'pb-16' : ''}`}>
                  {editorLoading && (
                    <div className="w-full h-full flex items-center justify-center">
                      <SpinningLoader />
                    </div>
                  )}
                  <div className={editorLoading ? 'hidden' : 'w-full h-full'}>
                    <DocumentEditor
                      ref={editorRef}
                      userId={auth.currentUser!.uid}
                      initialContent={generatedText}
                      initialTitle={template?.title}
                      templateId={template?.id}
                      onInit={handleEditorInit}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Bottom Navigation for Mobile */}
            {showBottomNav && (
              <div className="fixed bottom-0 left-0 right-0 md:left-[13rem] bg-white border-t border-gray-200 flex items-center h-14 px-4 z-10 space-x-4 lg:hidden">
                <Button
                  variant="outline"
                  onClick={event => {
                    event.preventDefault();
                    setActiveTab('input');
                  }}
                  className={`flex-1 h-10 text-sm ${activeTab === 'input' ? 'border-b-2 border-primary' : ''}`}
                >
                  Input
                </Button>
                <Button
                  variant="outline"
                  onClick={event => {
                    event.preventDefault();
                    setActiveTab('output');
                  }}
                  className={`flex-1 h-10 text-sm ${activeTab === 'output' ? 'border-b-2 border-primary' : ''}`}
                >
                  Output
                </Button>
              </div>
            )}
          </form>
          <UpgradeModal isOpen={showUpgradeModal} onRequestClose={() => setShowUpgradeModal(false)} />
        </LayoutInSide>
      </>
    );
  };
};

export default TemplateDetailPage;
