import { Zap } from "lucide-react"
import { cn } from "~/lib/utils"
import { motion } from "framer-motion"

interface SuggestionChipProps {
  suggestion: string;
  onClick: () => void;
  index: number;
  className?: string;
}

export const SuggestionChip = ({
  suggestion,
  onClick,
  index,
  className
}: SuggestionChipProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2, delay: index * 0.1 }}
    >
      <button
        data-testid={`suggestion-chip-${index}`}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }}
        className={cn(
          "w-full text-left px-3 py-1.5 my-[-2px] text-sm text-primary bg-purple-100/70 hover:bg-purple-200/70 rounded-md transition-colors",
          className
        )}
      >
        {suggestion}
      </button>
    </motion.div>
  )
}

export const SuggestionTitle = () => {
  return (
    <div className="flex items-center gap-1.5 text-xs text-primary mt-2 mb-1">
      <Zap className="h-3 w-3 text-purple-700" />
      <span>Vorschläge</span>
    </div>
  )
} 