import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

// Modular Fireabse SDK imports
import TemplateCard from "../../components/cards/TemplateCard";
import LayoutInSide from "../../layouts/LayoutInSide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useUserData } from "../../providers/UserDataProvider";


const CategoryPage = () => {
    const { templates } = useUserData();
    const location = useLocation();
    const paths = location.pathname.split('/');
    const category = decodeURIComponent(paths[3]);

    // Add loading check
    if (!templates) {
        return (
            <LayoutInSide>
                <div className="flex items-center justify-center h-[calc(100vh-200px)]">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
                </div>
            </LayoutInSide>
        );
    }

    // Transform templates to include required properties with default values
    const enrichedTemplates = templates.map(template => ({
        ...template,
        iconName: template.iconName || "fas fa-file-alt",
        iconColor: template.iconColor || "#1754FC",
        description: template.description || "Keine Beschreibung verfügbar", // Add default description
    }));

    return (
        <LayoutInSide>
            <div className="flex justify-between mb-4">
                <h3 className="pr-2 text-xl font-semibold font-Outfit whitespace-nowrap">{category}</h3>
                <Link to="/templates" className="text-[#AAA8BD] text-xs">
                    <FontAwesomeIcon icon={faArrowLeft}
                        className="pl-2 group-hover:text-white transition-colors duration-200 me-2" />
                    Zu allen Vorlagen
                </Link>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
                {enrichedTemplates
                    .filter((curtemplate) => curtemplate.type.toLowerCase() === category?.toLowerCase())
                    .map((curtemplate, index) => (
                        <div key={index} className="h-full">
                            <TemplateCard items={curtemplate} pinIconDisabled={true} />
                        </div>
                    ))
                }
            </div>
        </LayoutInSide>
    );
};

export default CategoryPage;